import {NavLink} from "react-router-dom";
import {Authentication} from "core/Infrastructure/services/Authentication";
import {History} from "core/Infrastructure/helpers/History";
import React from "react";

export const SideBar = (): JSX.Element => {
    const logout = () => {
        const auth: Authentication = new Authentication();

        auth.logout().then(() => {
            History.push('/login');
            document.location.reload();
        });
    }
    
    return (
        <div className="sideBar h-screen w-2/12 grid justify-items-center">
            <div className="side-actions-top">
                <div className="action">
                    <i className="fa-solid fa-bell"/>
                </div>
            </div>
            <div className="nav-link w-full">
                <NavLink to="/"
                         className={(navData) => ("grid grid-cols-2 justify-items-center items-center" + (navData.isActive ? " selected" : " unselected"))}>
                    <div className="nav-icon">
                        <i className="fa-solid fa-grid"/>
                    </div>
                    <span className="text-left w-full">Dashboard</span>
                </NavLink>
            </div>
            <div className="nav-link w-full">
                <NavLink to="/quotes/overview" className={(navData) => ("grid grid-cols-2 justify-items-center items-center" + (navData.isActive ? " selected" : " unselected"))}>
                    <div className="nav-icon">
                        <i className="fa-solid fa-folders"/>
                    </div>
                    <span className="text-left w-full">Quotes</span>
                </NavLink>
            </div>
            <div className="nav-link w-full">
                <NavLink to="/kits" className={ (navData) => ("grid grid-cols-2 justify-items-center items-center" + (navData.isActive ? " selected" : " unselected"))}>
                    <div className="nav-icon">
                        <i className="fa-solid fa-briefcase"/>
                    </div>
                    <span className="text-left w-full">Kits</span>
                </NavLink>
            </div>
            <div className="nav-link w-full">
                <NavLink to="/users" className={ (navData) => ("grid grid-cols-2 justify-items-center items-center" + (navData.isActive ? " selected" : " unselected"))}>
                    <div className="nav-icon">
                        <i className="fa-solid fa-users"/>
                    </div>
                    <span className="text-left w-full">Customers</span>
                </NavLink>
            </div>
            <div className="nav-link w-full">
                <NavLink to="/company" className={ (navData) => ("grid grid-cols-2 justify-items-center items-center" + (navData.isActive ? " selected" : " unselected"))}>
                    <div className="nav-icon">
                        <i className="fa-solid fa-building"/>
                    </div>
                    <span className="text-left w-full">Company</span>
                </NavLink>
            </div>
            <div className="nav-link w-full">
                <NavLink to="/profile" className={ (navData) => ("grid grid-cols-2 justify-items-center items-center" + (navData.isActive ? " selected" : " unselected"))}>
                    <div className="nav-icon">
                        <i className="fa-solid fa-circle-user"/>
                    </div>
                    <span className="text-left w-full">Profile</span>
                </NavLink>
            </div>
            <div className="w-full">
                <div className="grid grid-cols-2 justify-items-center items-center" onClick={() => {logout()}}>
                    <div className="nav-icon">
                        <i className="fa-solid fa-arrow-right-from-bracket"/>
                    </div>
                    <span className="text-left w-full">Log out</span>
                </div>
            </div>
        </div>
    );
}
