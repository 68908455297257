import {LoaderAPIService} from "../../../core/Infrastructure/services/LoaderAPIService";
import {RequestFailed} from "../../../core/Domain/exceptions/RequestFailed";
import {Toast} from "../../../LoaderUI";

export class ContactService extends LoaderAPIService {
    private readonly contactUrl: string;

    constructor() {
        super();
        this.contactUrl = this.getBaseUrl() + 'contact'
    }

    /**
     * Creates a new Customer
     *
     * @param {Partial<Kit>}
     * @returns {Promise<Object | boolean>}
     */
    async createContact({firstName, lastName, primaryTelephoneNumber, secondaryTelephoneNumber, email, customerId}: Partial<ContactDetails>): Promise<Object | boolean> {
        const requestOptions: Object = this.buildRequestOptions(
            'POST',
            this.getAuthHeader(),
            {
                firstName: firstName,
                lastName: lastName,
                primaryTelephoneNumber: primaryTelephoneNumber,
                secondaryTelephoneNumber: secondaryTelephoneNumber,
                email: email,
            }
        );

        let response = false;

        try {
            await this.request(this.contactUrl + '/' + customerId, requestOptions);

            response = true;
        } catch (error) {
            if (error instanceof RequestFailed) {
                Toast.error(error.message);
            }
        }

        return response
    }

    async getContacts(customerId: number): Promise<Object | boolean> {
        const requestOptions: Object = this.buildRequestOptions(
            'GET',
            this.getAuthHeader(),
        );

        return await this.request(this.contactUrl + '/' + customerId, requestOptions, 200, 'data');
    }
}
