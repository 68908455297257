import {Button, Input, Modal, TextArea, Toast} from "../../../../LoaderUI";
import {useEffect, useRef, useState} from "react";
import {SelectBox} from "../../../../LoaderUI/SelectBox";
import { IconInput } from "LoaderUI/IconInput";
import { DurationPicker } from "LoaderUI/DurationPicker";
import {CreateCustomer} from "../../../../Customer/Presentation/Modals/CreateCustomer";
import {CustomerService} from "../../../../Customer/Infrastructure/Services/CustomerService";
import { Quotes } from "Quotes/Infrastructure/services/Quotes";
import {CreateContact} from "../../../../Customer/Presentation/Modals/CreateContact";
import {ContactService} from "../../../../Customer/Infrastructure/Services/ContactService";

export const AddQuote = () => {
    const customerService = new CustomerService();
    const contactService = new ContactService();
    const quoteService = new Quotes();

    const quoteSubject = useRef({} as HTMLInputElement);
    const quoteDescription = useRef({} as HTMLTextAreaElement);
    const contingencyRate = useRef({} as HTMLInputElement);
    const softwareRate = useRef({} as HTMLInputElement);
    const workshopRate = useRef({} as HTMLInputElement);
    const contractRate = useRef({} as HTMLInputElement);
    const materialRate = useRef({} as HTMLInputElement);
    const selectContact = useRef({} as HTMLInputElement);
    const selectCustomer = useRef({} as HTMLInputElement);
    const [customers, setCustomers] = useState([] as SelectObject[]);
    const [contacts, setContacts] = useState([] as SelectObject[]);

    const [contractHours, setContractHours] = useState({} as DurationDetails);
    const [softwareHours, setSoftwareHours] = useState({} as DurationDetails);

    const [selectedCustomer, setSelectedCustomer] = useState(0 as number);
    const [selectedContact, setSelectedContact] = useState(0 as number);

    const [loaded, setLoaded] = useState(false as boolean);

    useEffect(() => {
        if (!loaded) {
            fetchCustomerList();
            setLoaded(true);
        }

        fetchContactList()
    }, [selectedCustomer]);

    const fetchCustomerList = () => {
        customerService.getCustomers().then(data => {
            setCustomers(data as SelectObject[]);
        })
    }

    const fetchContactList = () => {
        contactService.getContacts(selectedCustomer).then(data => {
            setContacts(data as SelectObject[]);
        })
    }

    const createQuote = () => {
        let subject = quoteSubject.current.value;
        let description = quoteDescription.current.value;
        let contingency = Number(contingencyRate.current.value);
        let software = Number(softwareRate.current.value);
        let workshop = Number(workshopRate.current.value);
        let contract = Number(contractRate.current.value);
        let material = Number(materialRate.current.value);

        let softwareHoursValue = softwareHours;
        let contractHoursValue = contractHours;

        quoteService.create(subject, description, contingency, software, workshop, contract, material, selectedContact, softwareHoursValue, contractHoursValue).then((data) => {
            if (data) {
                window.location.href = '/quotes/edit/' + data;
            } else {
                Toast.error('Failed to create Quote');
            }
        })
    }

    return (
        <div id="add-quotes-container" className="relative h-full w-full bg-gray-100 p-12">
             <div id="quote-creation-header">
                     <h1 className="text-xl">Create a Quote</h1>
                     <p className="text-base mt-2 text-gray-700">Fill out the details for your new Quote</p>
             </div>
            <div>
                <div id="customer-wrap" className="flex items-center mt-8">
                    <SelectBox action={(customer: number) => {
                        setSelectedCustomer(customer);
                    }} className="rounded-md w-3/6" list={customers} placeholder={'Select a customer...'} reference={selectCustomer} />
                     <Button className="bg-gray-700 rounded-md ml-1 p-1 px-2 cursor-pointer" action={() => {Modal.open(<CreateCustomer onClose={fetchCustomerList} />, false, 50, 55)}}>
                         <i className="fa-solid fa-plus text-gray-400 text-sm"></i>
                     </Button>
                </div>
                {selectedCustomer !== 0 ?
                    <div id="contact-wrap" className="flex items-center mt-8">
                        <SelectBox action={(contact: number) => {setSelectedContact(contact)}} className="rounded-md w-3/6" list={contacts} placeholder={'Select a contact...'} reference={selectCustomer} />
                         <Button className="bg-gray-700 rounded-md ml-1 p-1 px-2 cursor-pointer" action={() => {Modal.open(<CreateContact customerId={selectedCustomer} onClose={fetchContactList} />, false, 50, 65)}}>
                             <i className="fa-solid fa-plus text-gray-400 text-sm"></i>
                         </Button>
                    </div>  : ""
                }
                <Input
                    wrapperClassName="grid text-sm mt-8"
                    labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                    inputClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md"
                    reference={quoteSubject}
                    label="SUBJECT"
                    type="text"
                    disabled={false}
                    autoFocus={false}
                    placeholder=""
                />
                <TextArea
                    wrapperClassName="grid text-sm mt-8"
                    areaClassName="p-1.5 text-base disabled border border-gray-200 bg-gray-100 rounded-md resize-none h-40"
                    labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                    label="DESCRIPTION" reference={quoteDescription} />
                <div id="default-rates">
                    <IconInput
                        icon={<i className="fa-solid fa-sterling-sign text-gray-400 text-sm"></i>}
                        iconWrapperClassName="icon-wrapper bg-gray-700 rounded-sm p-1"
                        reference={workshopRate}
                        type="currency"
                        label="Workshop Rate"
                        placeholder="Optional"
                        labelClassName="text-sm text-center text-gray-600 w-full"
                        fullWrapperClassName="mt-8"
                        iconPosition="left"
                        wrapperClassName="bg-gray-100 w-8/12"
                        inputClassName="pl-1 bg-gray-100 rounded-md w-full outline-0 text-sm"
                    />
                    <div className="flex mt-8 w-4/6">
                        <IconInput
                            icon={<i className="fa-solid fa-sterling-sign text-gray-400 text-sm"></i>}
                            iconWrapperClassName="icon-wrapper bg-gray-700 rounded-sm p-1"
                            reference={softwareRate}
                            type="currency"
                            label="Software Rate"
                            placeholder="Optional"
                            labelClassName="text-sm text-gray-600 w-full"
                            iconPosition="left"
                            wrapperClassName="bg-gray-100 w-8/12"
                            inputClassName="pl-1 bg-gray-100 rounded-md w-full outline-0 text-sm"
                        />
                        <DurationPicker onChange={(timeValues: DurationDetails) => {setSoftwareHours(timeValues)}} inputLabelClassName="text-xs mb-2 text-center w-full block" inputWrapClassName="relative" timeWrapClassName="gap-2 mt-1" inputClassName="w-full bg-inherit text-center outline-none" includeSeconds={false} />
                    </div>
                    <div className="flex mt-8 w-4/6">
                        <IconInput
                            icon={<i className="fa-solid fa-sterling-sign text-gray-400 text-sm"></i>}
                            iconWrapperClassName="icon-wrapper bg-gray-700 rounded-sm p-1"
                            reference={contractRate}
                            type="currency"
                            label="Contract Rate"
                            placeholder="Optional"
                            labelClassName="text-sm text-gray-600 w-full"
                            iconPosition="left"
                            wrapperClassName="bg-gray-100 w-8/12"
                            inputClassName="pl-1 bg-gray-100 rounded-md w-full outline-0 text-sm"
                        />
                        <DurationPicker onChange={(timeValues: DurationDetails) => {setContractHours(timeValues)}} inputLabelClassName="text-xs mb-2 text-center w-full block" inputWrapClassName="relative" timeWrapClassName="gap-2 mt-1" inputClassName="w-full bg-inherit text-center outline-none" includeSeconds={false} />
                    </div>
                </div>
            </div>
            <div className="flex absolute bottom-20 right-5">
                <Button action={() => {Modal.close()}} className="ml-4 p-3 w-32 bg-gray-800 text-center items-center rounded-md text-sm hover:cursor-pointer">
                    <span className="text-gray-200 text-md">Close</span>
                </Button>
                <Button action={createQuote} className="ml-4 p-3 w-32 bg-gray-800 text-center items-center rounded-md text-sm hover:cursor-pointer">
                    <span className="text-gray-200 text-md">Create Quote</span>
                </Button>
            </div>
        </div>
    )
}