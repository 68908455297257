import { Button } from "LoaderUI/index";
import {useEffect, useRef} from "react"
import {SlideOutModal} from "../index";

type Props = {
    content: JSX.Element
}

export const SlideOutModalTemplate = ({content}: Props) => {
    const modalRef = useRef({} as HTMLDivElement);

    useEffect(() => {
       modalRef.current.style.transition = "width 1s";

        setTimeout(function () {
            modalRef.current.style.width = '35%'
        });
    });

    return (
       <div ref={modalRef} id="slide-out-modal" className="absolute right-0 w-0 overflow-hidden h-full delay-75 bg-gray-100 z-50 shadow-lg outline-0">
           <Button action={() => {SlideOutModal.close()}} className="cursor-pointer m-4">
               <i className="fa-solid fa-xmark text-3xl"></i>
           </Button>
           {content}
       </div>
    )
}