import {Input, TextArea, Button, Toast} from "LoaderUI"
import { DurationPicker } from "LoaderUI/DurationPicker"
import React, {useEffect, useRef, useState} from "react"
import {Parts} from "../../../../../Infrastructure/services/Parts";

type Props = {
    className: string
    close: Function
    updateAttachedParts: Function
}

export const PartDetails = ({className, close, updateAttachedParts}: Props) => {
    const addPartsButton = useRef({} as HTMLInputElement);
    const partNameRef = useRef({} as HTMLInputElement);
    const partDescriptionRef = useRef({} as HTMLTextAreaElement);
    const partFullLoadCurrentRef = useRef({} as HTMLInputElement);
    const partSpaceRef = useRef({} as HTMLInputElement);
    const partNetMaterialsRef = useRef({} as HTMLInputElement);
    const partDiscountRef = useRef({} as HTMLInputElement);
    const partTotalNetMaterialsRef = useRef({} as HTMLInputElement);
    const [partWorkshopHours, setPartWorkshopHours] = useState({
       hours: 0,
       minutes: 0,
       seconds: 0,
    } as DurationDetails);

    useEffect(() => {
        console.log('part details');
    });

    const addPart = () => {
        let partService = new Parts();

        let newPart: Part = {
            name: partNameRef.current.value,
            description: partDescriptionRef.current.value,
            fullLoadCurrent: parseFloat(partFullLoadCurrentRef.current.value),
            space: parseInt(partSpaceRef.current.value),
            installHours: partWorkshopHours,
            netMaterials: parseFloat(partNetMaterialsRef.current.value),
            discountRate: parseFloat(partDiscountRef.current.value),
            totalNetMaterials: parseFloat(partTotalNetMaterialsRef.current.value),
        }

        partService.createPart(newPart).then(response => {
            if (!response) {
                Toast.error('Error occurred whilst creating part');
            }

            let newPart = (response as Part[])[0] as Part;

            updateAttachedParts(newPart);

            Toast.success('Part created successfully and added to Kit');
         })
    }

    const updateTotalNetMaterials = (event: React.KeyboardEvent) => {
        let target = event.target as HTMLInputElement;

        let discountRate = target.id === 'part-discount-rate' ? parseFloat(target.value) : parseFloat(partDiscountRef.current.value);
        let netMaterials = target.id === 'part-net-materials' ? parseFloat(target.value) : parseFloat(partNetMaterialsRef.current.value);

        let totalNetMaterials = (isNaN(discountRate) ? 0 : discountRate) / 100 * (isNaN(netMaterials) ? 0 : netMaterials);

        (partTotalNetMaterialsRef.current as HTMLInputElement).value = String(netMaterials - totalNetMaterials);
    }

    return (
        <div id="part-creation-body" className={className}>
                <Input
                    wrapperClassName="grid text-sm w-full mt-8"
                    labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                    inputClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md"
                    reference={partNameRef}
                    label="PART NAME"
                    type="text"
                    disabled={false}
                    autoFocus={false}
                    placeholder=""
                />
                <TextArea
                    wrapperClassName="grid text-sm w-full mt-8"
                    areaClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md resize-none h-26"
                    labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                    label="DESCRIPTION" reference={partDescriptionRef}/>
                <div className="grid gap-6 grid-cols-2">

                    <div className="relative">
                        <Input
                            wrapperClassName="grid text-sm w-full mt-5"
                            labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                            inputClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md"
                            reference={partFullLoadCurrentRef}
                            label="FULL LOAD CURRENT"
                            type="number"
                            disabled={false}
                            autoFocus={false}
                            placeholder=""
                        />
                        <Input
                            wrapperClassName="grid text-sm w-full mt-5"
                            labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                            inputClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md"
                            reference={partSpaceRef}
                            label="SPACE"
                            type="number"
                            disabled={false}
                            autoFocus={false}
                            placeholder=""
                        />
                        <DurationPicker
                            wrapperClass="mt-4 "
                            label="INSTALL HOURS"
                            includeSeconds={false}
                            onChange={setPartWorkshopHours}
                            inputLabelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                            inputClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md"
                            inputWrapClassName="grid text-sm w-full mt-4"
                            labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                            timeWrapClassName="border border-gray-300 p-2 rounded-md"
                        />
                    </div>
                    <div>
                        <Input
                            wrapperClassName="grid text-sm w-full mt-5"
                            labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                            inputClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md"
                            reference={partNetMaterialsRef}
                            label="NET MATERIALS (£)"
                            type="currency"
                            disabled={false}
                            autoFocus={false}
                            placeholder=""
                            onChange={updateTotalNetMaterials}
                            id="part-net-materials"
                        />
                        <Input
                            wrapperClassName="grid text-sm w-full mt-5"
                            labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                            inputClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md"
                            reference={partDiscountRef}
                            label="DISCOUNT RATE (%)"
                            type="number"
                            disabled={false}
                            autoFocus={false}
                            placeholder=""
                            onChange={updateTotalNetMaterials}
                            id="part-discount-rate"
                        />
                        <Input
                            wrapperClassName="grid text-sm w-full mt-5"
                            labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                            inputClassName="p-1.5 text-base disabled w-full bg-gray-100 rounded-md"
                            reference={partTotalNetMaterialsRef}
                            label="TOTAL NET MATERIALS (£)"
                            type="number"
                            defaultValue={0}
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />
                    </div>
                </div>
                <div className="flex mt-6 ml-auto w-3/6 absolute bottom-0 right-0">
                    <Button
                        className="cursor-pointer ml-auto p-2 mb-3 mr-4 w-36 relative flex justify-center items-center border border-gray-300 rounded-md text-gray-100 bg-gray-500"
                        action={() => {
                            close(false)
                        }} reference={addPartsButton}>
                        <span className="text-center">Close</span>
                    </Button>
                    <Button
                        className="cursor-pointer ml-auto p-2 mb-3 mr-4 w-36 relative flex justify-center items-center border border-gray-300 rounded-md text-gray-200 bg-gray-800"
                        action={addPart} reference={addPartsButton}>
                        <span className="text-center">Create Part</span>
                    </Button>
                </div>
            </div>
    )
}