import {useEffect, useState} from "react";
import {Tabs} from "../../../../../../core/Presentation/components/Tabs";
import {AttachedParts} from "./AttachedParts";
import {KitDetails} from "./KitDetails";

type Props = {
    onClose?: Function
}

export const CreateKitModal = ({onClose}: Props) => {
    const tabs = ["Kit", "Assigned Parts"];
    const [selectedTab, setSelectedTab] = useState<string>("Kit");
    const [attachedParts, setAttachedParts] = useState([] as Part[]);

    useEffect(() => {
       updateKitTotals()
    }, [attachedParts])

    const [kitTotals, setTotals] = useState({
       fullLoadCurrent: 0,
       installHours: {
           hours: 0,
           minutes: 0,
           seconds: 0
       },
       space: 0,
       totalNetMaterials: 0
    } as Partial<Kit>);

    useEffect(() => {
        updateKitTotals()
    }, [attachedParts])

    const switchTab = (tab: string): void => {
        setSelectedTab(tab);
    }

    const updateTotals = (totals: Partial<Kit>) => {
        setTotals(totals);
    }

    const updateKitTotals = () => {
        let fullLoadCurrentTotal: number = 0;
        let kitTotalInstallHours = {
            hours: 0,
            minutes: 0,
            seconds: 0,
        } as DurationDetails;
        let space: number = 0;
        let kitTotalNetMaterials: number = 0.00;

        for (let i = 0; i < attachedParts.length; i++) {
            let part: Part = attachedParts[i];

            let installHours = part.installHours;

            let quantity = part.quantity ?? 1;

            let totalHours: number = ((installHours?.hours ?? 0) * quantity) + (kitTotalInstallHours.hours ?? 0);
            let totalMinutes: number = ((installHours?.minutes ?? 0) * quantity) + (kitTotalInstallHours.minutes ?? 0);
            let totalSeconds: number = ((installHours?.seconds ?? 0) * quantity) + (kitTotalInstallHours.seconds ?? 0);
            let totalFullLoadCurrent: number = ((part.fullLoadCurrent ?? 0) * quantity) + fullLoadCurrentTotal;
            let totalSpace: number = ((part.space ?? 0) * quantity) + space;
            let totalNetMaterials: number = ((part.totalNetMaterials ?? 0) * quantity) + kitTotalNetMaterials;

            fullLoadCurrentTotal = totalFullLoadCurrent;
            kitTotalInstallHours = {
                hours: totalHours,
                minutes: totalMinutes,
                seconds: totalSeconds,
            };
            space = totalSpace
            kitTotalNetMaterials = totalNetMaterials;
        }

        updateTotals({
            fullLoadCurrentTotal: fullLoadCurrentTotal,
            installHours: kitTotalInstallHours,
            space: space,
            totalNetMaterials: kitTotalNetMaterials,
        } as Partial<Kit>)
    }

    return (
         <div id="create-kit-modal" className="h-full relative p-8">
             <Tabs className="absolute right-0 self-end col-span-7 align-middle h-11" tabs={tabs} action={switchTab} selected={selectedTab} />
             <KitDetails kitParts={attachedParts} kitTotals={kitTotals} className={selectedTab === "Kit" ? "block" : "hidden"} />
             <AttachedParts attachedParts={attachedParts} updateParts={setAttachedParts} showNewPartForm={selectedTab !== "Kit"} className={selectedTab === "Assigned Parts" ? "block" : "hidden"} />
        </div>
    );
}