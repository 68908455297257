import {LoaderAPIService} from "../../../core/Infrastructure/services/LoaderAPIService";
import {RequestFailed} from "../../../core/Domain/exceptions/RequestFailed";
import {Toast} from "../../../LoaderUI";

export class QuoteLines extends LoaderAPIService {
    private readonly quoteLinesUrl: string;

    constructor() {
        super();
        this.quoteLinesUrl = this.getBaseUrl() + 'quote-lines'
    }

    async fetchQuoteLinesById(id: number): Promise<QuoteLineTemplate[] | boolean> {
        const requestOptions: Object = this.buildRequestOptions(
            'GET',
            this.getAuthHeader()
        );

        return await this.request(this.quoteLinesUrl + "/" + id, requestOptions, 200, 'data');
    }

     /**
     * Gets all Kits & Parts
     *
     * @returns {Promise<Array<Kit | Part> | boolean>}
     */
    async fetchQuoteLineTemplates(name: string | null = null): Promise<QuoteLineTemplate[] | boolean> {
        const requestOptions: Object = this.buildRequestOptions(
            'GET',
            this.getAuthHeader(),
        );

        let getParams = '';

        if (name !== null) {
            getParams = "/" + name;
        }

        return await this.request(this.quoteLinesUrl + getParams, requestOptions, 200, 'data');
    }

    async removeQuoteLine(quoteLine: QuoteLine): Promise<any> {
        const requestOptions: Object = this.buildRequestOptions(
            'DELETE',
            this.getAuthHeader(),
        );

        return this.request(this.quoteLinesUrl + "/" + quoteLine.id, requestOptions, 200, 'message');
    }

    async updateQuoteLine(quoteLine: QuoteLine) {
        const requestOptions: Object = this.buildRequestOptions(
            'PUT',
            this.getAuthHeader(),
            {
                quoteLine: quoteLine
            }
        );

        return await this.request(this.quoteLinesUrl + "/" + quoteLine.id, requestOptions, 200, 'message');
    }

    async insertQuoteLines(quoteId: number, kits: {}, parts: {}): Promise<any> {
        const requestOptions: Object = this.buildRequestOptions(
            'POST',
            this.getAuthHeader(),
            {
                quoteId: quoteId,
                parts: parts,
                kits: kits,
            }
        );

        let response = false;

        try {
            response = await this.request(this.quoteLinesUrl, requestOptions, 201, 'message');
        } catch (error) {
            if (error instanceof RequestFailed) {
                Toast.error(error.message);
            }
        }

        return response;
    }
}