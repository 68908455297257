import { TableRowMenu } from "LoaderUI/TableRowMenu";
import {Quotes} from "Quotes/Infrastructure/services/Quotes";
import React, {useRef} from "react";
import {Input} from "../../../LoaderUI";
import {Checkbox} from "../../../LoaderUI/Checkbox";

type Props = {
    name: string,
    description: string,
    index: number,
    updateTableItem: Function,
    isNew: boolean,
}

export const QuoteRowTemplate = ({name, description, index, isNew, updateTableItem}: Props): JSX.Element => {
    const quoteName = useRef<HTMLInputElement>({} as HTMLInputElement);
    const quoteDescription = useRef<HTMLInputElement>({} as HTMLInputElement);
    const quoteCheckbox = useRef<HTMLInputElement>({} as HTMLInputElement);
    const quotesService = new Quotes();

    const editRow = () => {
        console.log('Edit Quote');
    }

    const deleteRow = () => {
        console.log('Delete Quote');
    }

    const printQuote = () => {
        console.log('Print Quote');
    }

    const menuItems = [
        {
            label: "Edit",
            function: editRow,
            isDestructive: false,
        },
        {
            label: "Export",
            function: printQuote,
            isDestructive: false,
        },
        {
            label: "Send",
            function: printQuote,
            isDestructive: false,
        },
        {
            label: "Print",
            function: printQuote,
            isDestructive: false,
        },
        {
            label: "Delete",
            function: deleteRow,
            isDestructive: true,
        }
    ] as MenuAction[];
    return (
        <div className={"text-base text-gray-800 border-b border-gray-300 grid grid-cols-8 relative cursor-pointer hover:bg-gray-200" + (isNew ? "new-quote shadow-lg shadow-gray-300" : "")}>
            {!isNew ?
                <div className="z-50 absolute align-middle left-5 top-1/2 transform -translate-y-1/2">
                    <Checkbox reference={quoteCheckbox} />
                </div>
                : ''
            }
            <div className={"z-0 col-span-2 " + (isNew ? "p-0" : "indent-12 p-3")}>
                {(isNew ? <Input autoFocus={true} placeholder='Enter a name' reference={quoteName} type="text" inputClassName="is-new h-full bg-gray-100 p-3 placeholder:text-sm w-full" wrapperClassName="h-full w-full bg-gray-100 p-1"/> : name)}
            </div>
            <div className={"z-0 col-span-3 " + (isNew ? "p-0" : "p-3")}>
                {(isNew ? <Input placeholder="Enter a description" reference={quoteDescription} type="text" inputClassName="is-new h-full w-full bg-gray-100 p-3 placeholder:text-sm" wrapperClassName="h-full w-full bg-gray-100 p-1"/> : description)}
            </div>
            <div className="z-0 col-span-1 p-3 text-center">
            </div>
            <div className="z-0 col-span-1 p-3 text-center">
            </div>
            <div className="z-0 col-span-1 p-3 text-center">
            </div>
            {!isNew ?
                <div className="z-50 absolute align-middle right-5 top-1/2 transform -translate-y-1/2">
                    <TableRowMenu menuItems={menuItems}  />
                    {/*<i className="fa-solid fa-ellipsis-vertical"></i>*/}
                </div>
                : ''
            }
        </div>
    );
}