import {Authentication} from "core/Infrastructure/services/Authentication";
import {useRef} from "react";
import React from "react";
import {useNavigate} from "react-router-dom";

export const Login = (): JSX.Element => {
    const email = useRef<HTMLInputElement>({} as HTMLInputElement);
    const password = useRef<HTMLInputElement>({} as HTMLInputElement);
    const Navigate = useNavigate();

    const tryLogin = () => {
       let emailValue: string =  email.current.value ?? '';
       let passwordValue: string = password.current.value ?? '';

       let auth = new Authentication();
       
       auth.login(emailValue, passwordValue).then(response => {
           if (response) {
               Navigate('/')
           } else {
               Navigate('/login');
           }
       });
   } 
    
    return (
        <div>
            <h2>Login</h2>
            <input ref={email} type="text" id="email"/>
            <input ref={password} type="password" id="password"/>
            <input onClick={() => {tryLogin()}} type="button" value="Log in" />
        </div>
    )
}