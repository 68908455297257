import React from "react";
import {useEffect, useRef} from "react";
import {Toast} from "./index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleExclamation } from '@fortawesome/fontawesome-pro/svgs/duotone/';
import { library } from '@fortawesome/fontawesome-svg-core';

type Props = {
    header: string,
    message: string,
    icon: string,
    highlight: string
}

export function ToastTemplate({header, message, icon, highlight}: Props): JSX.Element {
    const iconRef = useRef<HTMLDivElement>({} as HTMLDivElement);

    useEffect(() => {
        Toast.slideIn()
        
        setTimeout(() => {
            Toast.slideOut(iconRef.current)
        }, 5000);
    });
    
    let iconClass: string = "toast-icon-";
    
    if (header === "Error") {
        iconClass += "error";
    } else if (header === "Success") {
        iconClass += "success";
    } else {
        iconClass += "info"; 
    }

    //
    // const myIcons = {
    //     warning: faCircleExclamation
    // }

    // library.add(faCircleExclamation);
    
    return (
        <div ref={iconRef} className="toast-wrapper">
            <div className="toast-highlight" style={{background: highlight}}/>
            {/*<FontAwesomeIcon icon={["fad", "circle-exclamation"]} className={iconClass}/>*/}
            {/*<FontAwesomeIcon icon={myIcons['warning']} className={iconClass} />*/}
            <div className="toast-content">
                <span className="toast-header">{header}</span>
                <span className="toast-message">{message}</span>
            </div>
            <div className="toast-close" onClick={(event) => {
                Toast.slideOut((event.target as Element));
            }}>
                <i className="fa-solid fa-xmark"/>
            </div>
        </div>
    );
}