import {Button} from "LoaderUI/Button";

type Props = {
    action: Function,
}

export const EditButton = ({action}: Props) => {
    return (
        <Button className="absolute right-5 top-2" action={action}>
            <i className="fa-solid fa-pen-to-square text-xl text-gray-900"></i>
        </Button>
    )
}