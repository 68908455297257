import React from "react";
import {useEffect, useRef, useState} from "react";
import {Quotes} from "Quotes/Infrastructure/services/Quotes";
import {QuoteRowTemplate} from "./QuoteRow";

export const QuotesOverviewTable = (): JSX.Element => {
    const [quotes, setQuotes] = useState<Quote[]>([] as Quote[]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const addQuoteButtons = useRef<HTMLTableRowElement>({} as HTMLTableRowElement);
    const newQuotes = new Quotes();

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);

            newQuotes.fetch().then(response  => {
                let responseArray = response as Array<Quote>;
                let newQuotes = [] as Quote[];

                if (response) {
                    newQuotes = responseArray.sort(function(a, b) {
                        let firstItem = a.id ?? 0;
                        let lastItem = b.id ?? 0;

                        return lastItem > firstItem ? 1 : -1;
                    });
                }

                setQuotes(newQuotes);
            });
        }
    }, [quotes, loaded]);

    const updateTableItem = (item: Quote, index: number) => {
        let currentQuotes = [...quotes];

        currentQuotes[index] = {
            ...currentQuotes[index],
            name: item.name,
            description: item.description,
            isNew: item.isNew,
        };

        setQuotes(currentQuotes);
    }

    return (
        <div className="w-full text-sm bg-gray-100 shadow-sm rounded-md h-5/6 grid">
            <div className="bg-gray-200 rounded-md relative grid grid-rows-1">
                <div className="rounded-t-md bg-gray-200 grid grid-cols-8">
                    <div className="indent-12 rounded-tl-md font-medium p-3 text-gray-500 text-left text-xs uppercase align-middle col-span-2">Subject</div>
                    <div className="font-medium p-3 text-gray-500 text-left text-xs uppercase align-middle col-span-3">Description</div>
                    <div className="font-medium p-3 text-gray-500 text-center text-xs uppercase align-middle col-span-1">Customer</div>
                    <div className="font-medium p-3 text-gray-500 text-center text-xs uppercase align-middle col-span-1">Status</div>
                    <div className="font-medium p-3 text-gray-500 text-center text-xs uppercase align-middle col-span-1">Total Price</div>
                </div>
            </div>
            <div id="kit-table-body grid grid-cols-8">
            {quotes.map((kit: Quote, index: number) => {
                    return (
                        <QuoteRowTemplate
                            key={'kit' + index}
                            name={kit.name}
                            description={kit.description}
                            index={index}
                            isNew={kit.isNew as boolean}
                            updateTableItem={updateTableItem as Function}
                        />
                    )
                }
            )}
            </div>
        </div>
    );
}