import {RequestFailed} from "core/Domain/exceptions/RequestFailed";

export class LoaderAPIService {
    private readonly baseUrl: string = process.env.REACT_APP_API_URL + "api/";
    private readonly token: string | null = localStorage.getItem('token');

    /**
     * Gets the base endpoint url
     *
     * @returns {string}
     */
    getBaseUrl = (): string => this.baseUrl;

    /**
     * Gets the Auth Header for API Requests
     *
     * @returns {{Authorization: string, "Content-Type": string}}
     */
    getAuthHeader = (): Object => ({'Authorization': `Bearer ${this.token}`, 'Content-Type': 'application/json'});

    /**
     * Builds request options for request to API
     *
     * @param method
     * @param headers
     * @param body
     * @return {Object}
     */
    buildRequestOptions = (method: string, headers: Object, body: Object | null = null): Object => {
        let responseObject: Object = {
            method: method,
            headers: headers,
        };

        if (body !== null) {
            Object.defineProperty(responseObject, 'body', {
                value: JSON.stringify(body)
            });
        }

        return responseObject;
    };

    /**
     * Sends a request to an API endpoint and runs some checks on the response
     *
     * @param url Endpoint for the API
     * @param request Request Object (Likely built using buildRequestOptions)
     * @param expectedResponseStatus
     * @param expectedResponseProperty - Expected response object property
     * @param expectedResponsePropertyMatch - Expected response object property match
     * @returns {Promise<any | boolean>}
     */
    request = (
        url: string,
        request: Object,
        expectedResponseStatus: number = 200,
        expectedResponseProperty: string = 'message' as keyof Response,
        expectedResponsePropertyMatch: string | null = null
    ): Promise<any | boolean> => fetch(url, request)
        .then(async response => {
            let responseObject: Response = await response.json();

            if (response.status === 401 && responseObject.hasOwnProperty('message') && responseObject.message === 'Expired JWT Token') {
                localStorage.removeItem('token');
                window.location.replace( process.env.REACT_APP_URL + "login");
            }

            if (
                (expectedResponseProperty !== 'message'
                    && !responseObject.hasOwnProperty(expectedResponseProperty)
                    && responseObject.hasOwnProperty('message'))
                || (responseObject.hasOwnProperty('message')
                    && responseObject.hasOwnProperty('status')
                    && responseObject.status === expectedResponseStatus)
            ) {
                throw new RequestFailed(responseObject.message);
            } else if (!responseObject.hasOwnProperty(expectedResponseProperty)) {
                throw new RequestFailed('Something went wrong, please contact support');
            }

            if (expectedResponsePropertyMatch !== null) {
                return responseObject[expectedResponseProperty as keyof Response] === expectedResponsePropertyMatch;
            }

            return responseObject[expectedResponseProperty as keyof Response];
        });
}