import {Input} from "../../../LoaderUI";
import React, {useEffect, useRef, useState} from "react";
import {IconInput} from "../../../LoaderUI/IconInput";
import {DurationPicker} from "../../../LoaderUI/DurationPicker";
import {Quotes} from "../../Infrastructure/services/Quotes";

type Props = {
    quoteTotals: QuoteTotalsDetails
    setQuoteTotals: Function
    quoteId: number,
}

export const QuoteTotals = ({quoteTotals, setQuoteTotals, quoteId}: Props) => {
    const quoteService = new Quotes();

    let totalCostRef = useRef({} as HTMLInputElement);
    let totalSpaceRef = useRef({} as HTMLInputElement);
    let totalCurrentRef = useRef({} as HTMLInputElement);

    let materialRateRef = useRef({} as HTMLInputElement);
    let materialTotalRef = useRef({} as HTMLInputElement);

    let contingencyRateRef = useRef({} as HTMLInputElement);
    let contingencyTotalRef = useRef({} as HTMLInputElement);

    let softwareRateRef = useRef({} as HTMLInputElement);
    let softwareTotalRef = useRef({} as HTMLInputElement);
    let softwareHoursRef = useRef({} as HTMLInputElement);

    let workshopRateRef = useRef({} as HTMLInputElement);
    let workshopHoursRef = useRef({} as HTMLInputElement);
    let workshopCostRef = useRef({} as HTMLInputElement);

    let contractRateRef = useRef({} as HTMLInputElement);
    let contractHoursRef = useRef({} as HTMLInputElement);
    let contractCostRef = useRef({} as HTMLInputElement);

    let softwareDefaultHours = useRef({} as DurationDetails);
    let contractDefaultHours = useRef({} as DurationDetails);

    const [saveQuoteTotals, setSaveQuoteTotals] = useState(null as Partial<QuoteTotalsDetails> | null);

    useEffect(() => {
        contractRateRef.current.value = String((quoteTotals.contractRate ?? 0).toFixed(2));
        contractHoursRef.current.value = String(quoteTotals.contractHours);
        contractCostRef.current.value = String("£" + (quoteTotals.contractCost ?? 0).toFixed(2));

        workshopRateRef.current.value = String((quoteTotals.workshopRate ?? 0).toFixed(2));

        let workshopHours = quoteTotals.workshopHours ?? {
            hours: 0,
            minutes: 0,
            seconds: 0,
        } as DurationDetails

        if ((workshopHours.seconds ?? 0) >= 60) {
            let incrementMinutes = ((workshopHours.seconds ?? 0) / 60).toFixed(0);

            workshopHours.minutes = workshopHours.minutes + Number(incrementMinutes);
            workshopHours.minutes = workshopHours.minutes + Number(incrementMinutes);

            workshopHours.seconds = ((workshopHours.seconds ?? 0) % 60);
        }

        if (workshopHours.minutes >= 60) {
            let incrementHours = (workshopHours.minutes / 60).toFixed(0);

            workshopHours.hours = workshopHours.hours + Number(incrementHours);

            workshopHours.minutes = (workshopHours.minutes % 60);
        }

        let totalWorkshopHours = (workshopHours.hours ?? 0) < 10 ? "0" + (workshopHours.hours ?? 0) : (workshopHours.hours ?? 0);
        let totalWorkshopMinutes = (workshopHours.minutes ?? 0) < 10 ? "0" + (workshopHours.minutes ?? 0) : (workshopHours.minutes ?? 0);

        workshopHoursRef.current.value = String(totalWorkshopHours + ":" + totalWorkshopMinutes);
        workshopCostRef.current.value = String("£" + (quoteTotals.workshopCost ?? 0).toFixed(2));

        softwareRateRef.current.value = String((quoteTotals.softwareRate ?? 0).toFixed(2));
        softwareTotalRef.current.value = String("£" + (quoteTotals.softwareCost ?? 0).toFixed(2));

        materialTotalRef.current.value = String("£" + (quoteTotals.materialCost ?? 0).toFixed(2));
        materialRateRef.current.value = String((quoteTotals.materialRate ?? 0).toFixed(2));

        contingencyTotalRef.current.value = String("£" + (quoteTotals.contingencyCost ?? 0).toFixed(2));
        contingencyRateRef.current.value = String((quoteTotals.contingencyRate ?? 0).toFixed(2));

        totalCostRef.current.value = String("£" + ((quoteTotals.materialCost ?? 0) + (quoteTotals.contingencyCost ?? 0) + (quoteTotals.workshopCost ?? 0) + (quoteTotals.softwareCost ?? 0) + (quoteTotals.contractCost ?? 0)).toFixed(2));
        totalSpaceRef.current.value = String(((quoteTotals.totalSpace ?? 0) * 1.22 * 1.5).toFixed(2));
        totalCurrentRef.current.value = String((quoteTotals.totalCurrent ?? 0).toFixed(2));

        softwareDefaultHours.current = quoteTotals.softwareHours ?? {
            hours: 0,
            minutes: 0,
            seconds: 0,
        }

        contractDefaultHours.current = quoteTotals.contractHours ?? {
            hours: 0,
            minutes: 0,
            seconds: 0,
        }

        if (saveQuoteTotals !== null) {
            const timer = setTimeout(() => {
                quoteService.updateTotals(quoteId, saveQuoteTotals)
                setSaveQuoteTotals(null)
            }, 1000);
            return () => {
                clearTimeout(timer);
            }
        }
    });

    const updateTotals = (argument: React.KeyboardEvent | DurationDetails, type?: string) => {
        let softwareHours = type === 'software' ? argument : softwareDefaultHours.current;
        let contractHours = type === 'contract' ? argument : contractDefaultHours.current;

        let newContractRate = contractRateRef.current.value.includes('.') ? Number(contractRateRef.current.value) : parseInt(contractRateRef.current.value);
        let newWorkshopRate = workshopRateRef.current.value.includes('.') ? Number(workshopRateRef.current.value) : parseInt(workshopRateRef.current.value);
        let newSoftwareRate = softwareRateRef.current.value.includes('.') ? Number(softwareRateRef.current.value) : parseInt(softwareRateRef.current.value);
        let newMaterialRate = materialRateRef.current.value.includes('.') ? Number(materialRateRef.current.value) : parseInt(materialRateRef.current.value);
        let newContingencyRate = contingencyRateRef.current.value.includes('.') ? Number(contingencyRateRef.current.value) : parseInt(contingencyRateRef.current.value);

        let newQuoteTotals = {
            contractRate:  newContractRate,
            workshopRate: newWorkshopRate,
            softwareRate: newSoftwareRate,
            materialRate: newMaterialRate,
            contingencyRate: newContingencyRate,
            softwareHours: softwareHours as DurationDetails,
            contractHours: contractHours as DurationDetails,
            totalSpace: parseFloat(totalSpaceRef.current.value),
            totalFullLoadCurrent: parseFloat(totalCurrentRef.current.value),
            totalPrice: parseFloat(totalCostRef.current.value),
        } as Partial<QuoteTotalsDetails>

        setSaveQuoteTotals(newQuoteTotals);

        setQuoteTotals(newQuoteTotals)
    }

    return (
        <div id="quote-totals" className="h-4/6 grid col-span-3 gap-5">
            <div id="cost-totals-card" className="grid grid-cols-3 p-2 border border-gray-300 bg-gray-800 rounded-md auto-cols-max">
                <Input
                    wrapperClassName="grid text-sm py-4 px-1 w-full"
                    labelClassName="pl-1 text-sm text-gray-400 font-semibold w-full"
                    inputClassName="pl-1.5 text-lg text-gray-100 bg-gray-800 disabled w-full"
                    reference={totalCostRef}
                    label="Total Cost"
                    type="text"
                    disabled={true}
                    autoFocus={false}
                    placeholder=""
                />
                <Input
                    wrapperClassName="grid text-sm py-4 px-1 w-full"
                    labelClassName="text-sm text-gray-400 text-center font-semibold w-full"
                    inputClassName="text-lg text-center text-gray-100 bg-gray-800 disabled w-full"
                    reference={totalSpaceRef}
                    label="Total Space"
                    type="text"
                    disabled={true}
                    autoFocus={false}
                    placeholder=""
                />
                <Input
                    wrapperClassName="grid text-sm py-4 px-1 w-full"
                    labelClassName="text-sm text-gray-400 text-center font-semibold w-full"
                    inputClassName="text-lg text-center text-gray-100 bg-gray-800 disabled w-full"
                    reference={totalCurrentRef}
                    label="Total FLC"
                    type="text"
                    disabled={true}
                    autoFocus={false}
                    placeholder=""
                />
            </div>
            <div id="man-hour-rates" className="col-span-1">
                <h3 className="p-2 text-sm">Man-hour Rates</h3>
                <div id="man-hours-container" className="grid grid-cols-1 border border-gray-300 bg-gray-100 rounded-md auto-cols-max">
                    <div id="workshop-hours" className="grid py-4 grid-cols-3 border-b border-b-gray-300">
                        <h2 className="pl-4 col-span-3 mb-4">Workshop</h2>
                        <Input
                            wrapperClassName="grid text-sm ml-4 mt-1 w-full"
                            labelClassName="pl-1 text-sm text-gray-600 font-semibold w-full"
                            inputClassName="pl-1.5 text-base text-gray-900 disabled w-full"
                            reference={workshopHoursRef}
                            label="Hours"
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />
                        <IconInput
                            icon={<i className="fa-solid fa-sterling-sign text-gray-400  text-sm"></i>}
                            iconWrapperClassName="icon-wrapper bg-gray-700 rounded-sm p-1"
                            reference={workshopRateRef}
                            type="currency"
                            onChange={updateTotals}
                            label="Rate"
                            labelClassName="pl-4 text-sm text-gray-600 font-semibold w-full"
                            iconPosition="left"
                            fullWrapperClassName={"ml-4"}
                            wrapperClassName="bg-gray-100 w-8/12"
                            inputClassName="pl-1 bg-gray-100 rounded-md w-full outline-0 "
                        />
                        <Input
                            wrapperClassName="grid text-sm ml-4 mt-1 w-full"
                            labelClassName="pl-1 text-sm text-gray-600 font-semibold w-full"
                            inputClassName="pl-1.5 text-base text-gray-900 disabled w-full"
                            reference={workshopCostRef}
                            label="Total"
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />
                    </div>
                    <div id="software-hours" className="grid grid-cols-3 py-4 border-b border-b-gray-300">
                        <h2 className="pl-4 col-span-3 mb-4">Software</h2>
                        <DurationPicker onChange={
                            (hours: DurationDetails) => {
                                softwareDefaultHours.current = hours;
                                updateTotals(hours, 'software');
                            }
                        } defaultValue={quoteTotals.softwareHours as DurationDetails} inputLabelClassName="text-xs mb-2 text-center w-full block" inputWrapClassName="relative" timeWrapClassName="gap-2 mt-1" inputClassName="w-full bg-inherit text-center outline-none" includeSeconds={false} />
                        <IconInput
                            icon={<i className="fa-solid fa-sterling-sign text-gray-400 text-sm"></i>}
                            iconWrapperClassName="icon-wrapper bg-gray-700 rounded-sm p-1"
                            reference={softwareRateRef}
                            type="currency"
                            onChange={updateTotals}
                            label="Rate"
                            labelClassName="pl-4 text-sm text-gray-600 font-semibold w-full"
                            fullWrapperClassName="ml-4"
                            iconPosition="left"
                            wrapperClassName="bg-gray-100 w-8/12"
                            inputClassName="pl-1 bg-gray-100 rounded-md w-full outline-0 "
                        />
                        <Input
                            wrapperClassName="grid text-sm mt-1  ml-4 w-full"
                            labelClassName="pl-1 text-sm text-gray-600 font-semibold w-full"
                            inputClassName="pl-1.5 text-base text-gray-900 disabled w-full"
                            reference={softwareTotalRef}
                            label="Total"
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />
                    </div>
                    <div id="contract-hours" className="grid grid-cols-3 py-4">
                        <h2 className="pl-4 col-span-3 mb-4">Contract</h2>
                        <DurationPicker onChange={(hours: DurationDetails) => {
                            contractDefaultHours.current = hours;
                            updateTotals(hours, 'contract');
                        }} defaultValue={quoteTotals.contractHours as DurationDetails} inputLabelClassName="text-xs mb-2 text-center w-full block" inputWrapClassName="relative" timeWrapClassName="gap-2 mt-1" inputClassName="w-full bg-inherit text-center outline-none" includeSeconds={false} />
                        <IconInput
                            icon={<i className="fa-solid fa-sterling-sign text-gray-400 text-sm"></i>}
                            iconWrapperClassName="icon-wrapper bg-gray-700 rounded-sm p-1"
                            reference={contractRateRef}
                            type="currency"
                            onChange={updateTotals}
                            label="Rate"
                            labelClassName="pl-4 text-sm text-gray-600 font-semibold w-full"
                            fullWrapperClassName="ml-4"
                            iconPosition="left"
                            wrapperClassName="bg-gray-100 w-8/12"
                            inputClassName="pl-1 bg-gray-100 rounded-md w-full outline-0 "
                        />
                        <Input
                            wrapperClassName="grid text-sm ml-4 mt-1 w-full"
                            labelClassName="pl-1 text-sm text-gray-600 font-semibold w-full"
                            inputClassName="pl-1.5 text-base text-gray-900 disabled w-full"
                            reference={contractCostRef}
                            label="Total"
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />

                    </div>
                </div>
            </div>
            <div id="overhead-rates" className="col-span-1">
                <h3 className="p-2 text-sm">Overhead Rates</h3>
                <div id="overheads-container" className="grid grid-cols-1 border border-gray-300 bg-gray-100 rounded-md auto-cols-max">
                    <div id="material-overhead" className="grid p-4 grid-cols-2 border-b border-b-gray-300">
                        <h2 className="pl-4 col-span-3 mb-4">Materials</h2>
                        <IconInput
                            icon={<i className="fa-solid fa-percentage text-gray-400 text-sm"></i>}
                            iconWrapperClassName="icon-wrapper bg-gray-700 rounded-sm p-1"
                            reference={materialRateRef}
                            type="currency"
                            onChange={updateTotals}
                            label="Rate"
                            labelClassName="pl-4 text-sm text-gray-600 font-semibold w-full"
                            iconPosition="right"
                            fullWrapperClassName={"ml-4"}
                            wrapperClassName="bg-gray-100 w-8/12 ml-4"
                            inputClassName="pl-1.5 mr-2 bg-gray-100 rounded-md w-full outline-0 "
                        />
                        <Input
                            wrapperClassName="mt-1 ml-8 text-sm w-full"
                            labelClassName="pl-1 text-sm text-gray-600 font-semibold w-full"
                            inputClassName="pl-1.5 text-base text-gray-900 disabled w-full"
                            reference={materialTotalRef}
                            label="Total"
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />

                    </div>
                    <div id="contingency-overhead" className="grid grid-cols-2 p-4">
                        <h2 className="pl-4 col-span-3 mb-4">Contingency</h2>
                        <IconInput
                            icon={<i className="fa-solid fa-percentage text-gray-400 text-sm"></i>}
                            iconWrapperClassName="icon-wrapper p-1 rounded-sm bg-gray-700"
                            reference={contingencyRateRef}
                            type="currency"
                            onChange={updateTotals}
                            label="Rate"
                            labelClassName="pl-4 text-sm text-gray-600 font-semibold w-full"
                            fullWrapperClassName="ml-4"
                            iconPosition="right"
                            wrapperClassName="bg-gray-100 w-8/12 ml-4"
                            inputClassName="px-1.5 bg-gray-100 rounded-md w-full outline-0 "
                        />
                        <Input
                            wrapperClassName="grid text-sm ml-8 mt-1 w-full"
                            labelClassName="pl-1 text-sm text-gray-600 font-semibold w-full"
                            inputClassName="pl-1.5 text-base text-gray-900 disabled w-full"
                            reference={contingencyTotalRef}
                            label="Total"
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />

                    </div>
                </div>
            </div>
        </div>
    );
}