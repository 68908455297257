import {Button, Counter} from "../../../../../../LoaderUI";

type Props = {
    part: Part
    removePart: Function
    quantityChange: Function
    index: number
}

export const CreateKitModalRow = ({part, removePart, quantityChange, index}: Props) => {
    return (
        <tr className="w-full block bg-gray-200 mb-2 rounded-md table border-spacing-3 relative p-4 hover:bg-gray-200 text-sm text-gray-800 shadow-md hover:cursor-pointer">
            <td className="w-1/6 inline-block font-medium p-3 text-gray-500 text-left text-xs uppercase overflow-hidden text-ellipsis whitespace-nowrap">{part.name}</td>
            <td className="w-1/6 inline-block font-medium p-3 text-gray-500 text-center text-xs uppercase">
                <Counter defaultValue={part.quantity ?? 1} allowArrows={true} onChange={quantityChange} index={index} wrapperClassName="justify-center" />
            </td>
            <td className="w-1/6 inline-block font-medium p-3 text-gray-500 text-center text-xs uppercase">{part.space}</td>
            <td className="w-1/6 inline-block font-medium p-3 text-gray-500 text-center text-xs uppercase">{part.netMaterials}</td>
            <td className="w-1/6 inline-block font-medium p-3 text-gray-500 text-center text-xs uppercase">{part.discountRate ?? 0}</td>
            <td className="w-1/6 inline-block font-medium p-3 text-gray-500 text-center text-xs uppercase">{part.totalNetMaterials ?? 0.00}</td>
            <td className="absolute right-5 moreInfo pt-0.5 top-1/4">
                 <Button className="moreInfo" action={() => {removePart(part, true)}}>
                     <i className="fa-solid fa-xmark"></i>
                </Button>
            </td>
        </tr>
    )
}