import {Button, Counter} from "LoaderUI";
import {useState} from "react";

type Props = {
    quoteLines:QuoteLineTemplate[]
    quantityChange: Function
    updateCart: Function
    overwriteCart: Function
}

export const CartPopup = ({quoteLines, quantityChange, overwriteCart}: Props) => {
    const [cart, setCart] = useState(quoteLines as QuoteLineTemplate[]);

    const removeFromCart = (quoteLine: QuoteLineTemplate) => {
        let currentCart = [...cart];

        let existingObject = currentCart.find(cartItem => cartItem.id === quoteLine.id);
        let index = currentCart.indexOf(existingObject as QuoteLineTemplate);

        currentCart.splice(index, 1);

        overwriteCart(currentCart);

        setCart(currentCart);
    }

    return (
        <div className="p-2 bg-gray-100 w-80">
            <h2 className="p-1 font-bold mb-2">My Cart <span className="text-gray-500">({cart.length})</span></h2>
            <div className="cart-body max-h-96 pr-4 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                {cart.map((quoteLine, quoteLineIndex) => {
                    const type = quoteLine.type[0].toUpperCase() + quoteLine.type.substring(1);

                    return (
                        <div className="relative border border-b-gray-300 rounded-md p-1 mb-2" key={"cart-item-" + quoteLineIndex}>
                            <div className="cart-item-body">
                                <p className="pl-2 text-sm font-light text-gray-400"><span className="text-gray-400 text-sm font-medium">{type}</span></p>
                                <span className="uppercase font-medium block pt-2 pr-4 pl-2 text-ellipsis whitespace-nowrap overflow-hidden">{quoteLine.name}</span>
                                <div className="cart-item-extra-info grid grid-cols-3 grid-rows-2 flex p-2">
                                    <span className="pt-2 text-center text-sm font-light text-gray-500">Net Mat</span>
                                    <span className="pt-2 text-center text-sm font-light text-gray-500">FLC</span>
                                    <span className="pt-2 text-center text-sm font-light text-gray-500">Space</span>
                                    <span className="pt-2 text-center text-gray-900 text-sm font-medium">{quoteLine.netMaterials ?? 0}</span>
                                    <span className="pt-2 text-center text-gray-900 text-sm font-medium">{quoteLine.fullLoadCurrent ?? 0}</span>
                                    <span className="pt-2 text-center text-gray-900 text-sm font-medium">{quoteLine.space ?? 0}</span>
                                </div>
                           </div>
                            <div className="cart-item-footer pl-8 py-4 grid space-x-8 grid-cols-2 ">
                                <Counter defaultValue={quoteLine.quantity ?? 1} allowArrows={true} onChange={quantityChange} index={quoteLineIndex} />
                                <span className="text-lg font-normal">£ 400</span>
                            </div>
                            <Button className="cursor-pointer" action={() => {
                                    removeFromCart(quoteLine);
                            }}>
                                <i className="absolute right-5 top-2  fa-solid fa-minus"></i>
                            </Button>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}