import React, {useRef} from "react";
import {Input} from "../../../../LoaderUI";
import {Kits} from "../../../Infrastructure/services/Kits";

type Props = {
   name: string,
   description: string,
   fullLoadCurrent: number,
   space: number,
   netMaterials: number,
   totalHours: DurationDetails,
   isNew: boolean,
   index: number,
   updateTableItem: Function,
}

export const KitRow = ({name, description, fullLoadCurrent, space, netMaterials, totalHours, isNew, index, updateTableItem}: Props): JSX.Element => {
    const kitName = useRef<HTMLInputElement>({} as HTMLInputElement);
    const kitDescription = useRef<HTMLInputElement>({} as HTMLInputElement);

    const handleKeyPress = (event: React.KeyboardEvent) => {
       if (event.key === 'Enter' && isNew) {
           let kitsService = new Kits();
           let newName = kitName.current.value;
           let newDescription = kitDescription.current.value;

           let newKit: Kit = {
               name: newName,
               description: newDescription,
               fullLoadCurrentTotal: fullLoadCurrent,
               space: space,
               totalNetMaterials: netMaterials,
               installHours: totalHours,
               isNew: false,
           }

           kitsService.createKit(newKit).then(response => {
               if (response) {
                   updateTableItem(newKit, index);
               }
           });
       }
    }

    let hours = (totalHours.hours < 10) ? '0' + totalHours.hours : totalHours.hours;
    let minutes = (totalHours.minutes < 10) ? '0' + totalHours.minutes : totalHours.minutes;
    let seconds = ((totalHours.seconds ?? 0) < 10) ? '0' + totalHours.seconds : totalHours.seconds;

    return (
        <tr className={"text-base text-gray-800 border-b border-gray-300 " + (isNew ? "new-kit shadow-lg shadow-gray-300" : "")}>
            <td className={isNew ? "p-0" : "p-3"}>
                {(isNew ? <Input onKeyPress={handleKeyPress} autoFocus={true} placeholder='Enter a name' reference={kitName} type="text" inputClassName="is-new h-full bg-gray-100 p-3 placeholder:text-sm w-full" wrapperClassName="h-full w-full bg-gray-100 p-1"/> : name)}
            </td>
            <td className={isNew ? "p-0" : "p-3"}>
                {(isNew ? <Input onKeyPress={handleKeyPress} placeholder="Enter a description" reference={kitDescription} type="text" inputClassName="is-new h-full w-full bg-gray-100 p-3 placeholder:text-sm" wrapperClassName="h-full w-full bg-gray-100 p-1"/> : description)}
            </td>
            <td className="p-3 text-center">
                {fullLoadCurrent}
            </td>
            <td className="p-3 text-center">
                {space}
            </td>
            <td className="p-3 text-center">
                {netMaterials}
            </td>
            <td className="p-3">
                {hours + ":" + minutes + ":" + seconds}
            </td>
        </tr>
    );
}
