import React from "react";

type Props = {
   part: Part,
   updateAttachedParts: Function
}

export const PartSearchRow = ({part, updateAttachedParts}: Props): JSX.Element => {
    let totalInstallHours = part.installHours?.hours + ":" + part.installHours?.minutes + ":" + part?.installHours?.seconds;

    return (
        <tr className={"w-full block bg-gray-200 mb-2 rounded-md table border-spacing-3 relative p-4 hover:bg-gray-200 text-sm text-gray-800 shadow-md hover:cursor-pointer"}>
            <td className="absolute left-5 pt-0.5 top-1/4" onClick={() => {updateAttachedParts(part)}}>
                <i className="fa-solid fa-plus"  id={"rowAdd" + part.id}></i>
            </td>
            <td className={"w-3/12 pl-14 p-3 overflow-hidden text-ellipsis whitespace-nowrap"}>
                {part.name}
            </td>
            <td className="w-3/12 p-3 text-center">
                {totalInstallHours}
            </td>
            <td className="w-2/12 p-3 text-center">
                {part.netMaterials}
            </td>
            <td className="w-2/12 p-3 text-center">
                {part.discountRate}
            </td>
            <td className="w-2/12 p-3 text-center">
                {part.totalNetMaterials}
            </td>
        </tr>
    );
}