import React from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {QuotesOverview} from "./Quotes/Presentation/views/QuotesOverview";
import {Dashboard} from "./core/Presentation/views/Dashboard";
import {Login} from "./core/Presentation/views/Login";
import {ProtectedRoute} from "./core/Presentation/components/ProtectedRoute";
import {Kits} from "./Kits/Presentation/views/Kits";
import { Quote } from 'Quotes/Presentation/views/Quote';
import {AddQuote} from "./Quotes/Presentation/components/AddQuote/AddQuote";

export const App = () => {
  return (
    <div id="app-root" className="App w-full h-full">
      <Router>
          <Routes>
              <Route path="/" element={<ProtectedRoute content={<Dashboard />} />} />
              <Route path="/kits" element={<ProtectedRoute content={<Kits />} />} />
              <Route path="/quotes/overview" element={<ProtectedRoute content={<QuotesOverview />} />} />
              <Route path="/quotes/edit/:id" element={<ProtectedRoute content={<Quote />} /> } />
              <Route path="/quotes/new" element={<ProtectedRoute content={<AddQuote />} />} />
              <Route path="/login" element={<Login />} />
          </Routes>
      </Router>
    </div>
  );
}