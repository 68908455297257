import React from 'react';

type Props = {
    label: string,
    reference: React.Ref<HTMLTextAreaElement>,
    wrapperClassName?: string,
    labelClassName?: string,
    areaClassName?: string,
}

export const TextArea = ({label, reference, wrapperClassName, labelClassName, areaClassName}: Props): JSX.Element => {
    return (
        <div className={wrapperClassName ?? ''}>
            <label className={labelClassName ?? ''}>{label}</label>
            <textarea className={areaClassName ?? ''} ref={reference}/>
        </div>
    )
}