import React from "react";
import {useEffect, useRef, useState} from "react";
import {IconInput} from "../IconInput";

type Props = {
   list:  SelectObject[],
   action?: Function,
   placeholder: string,
   reference: React.Ref<HTMLInputElement>,
   actionKey?: string,
   className?: string,
}

export const SelectBox = ({list, action, placeholder, actionKey, className}: Props): JSX.Element => {
    const selectInput = useRef<HTMLInputElement>({} as HTMLInputElement);
    const selectBoxList = useRef<HTMLInputElement>({} as HTMLInputElement);

    const [listArray, setListArray] = useState<SelectObject[]>([] as SelectObject[]);
    const [loaded, setLoaded] = useState<boolean>(false);
    
    useEffect(() => {
        if (listArray.length > 0) {
           let placeholderString: string = listArray[0].name;

           if (placeholderString === placeholder) {
               listArray.shift();
           }

           if (list.length > 0) {
                list.forEach(function (object) {
                   if (object.name === selectInput.current.value) {
                       return;
                   }

                   selectInput.current.value = placeholder;
               });
           } else {
               selectInput.current.value = placeholder;
           }
        }

        if (!loaded || list !== listArray) {
            list.unshift({"id": 0, name: placeholder})
            setListArray(list);
            setLoaded(true);
        }
    }, [list, listArray, loaded, placeholder])

    const search = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        let searchValue = (event.target as HTMLInputElement).value;

        let newList: SelectObject[] = list.filter((item) => item.name.indexOf(searchValue) !== -1);

        setListArray(newList);
    }
    
    const showList = (): void => {
        selectBoxList.current.style.display = 'block';
    }

    const hideList = (event: React.FocusEvent | null = null, forceClose = false) => {
        if (forceClose) {
            selectBoxList.current.style.display = 'none';

            return;
        }

        const relatedTarget: Element | null = event !== null ? event.relatedTarget : null;

        if (relatedTarget !== null) {
            if ((relatedTarget.tagName !== null && relatedTarget.tagName !== "SPAN")) {
                selectBoxList.current.style.display = 'none';
            }
        } else {
            selectBoxList.current.style.display = 'none';
        }
    };

    const selectItem = (event: React.MouseEvent) => {
        let target: Element | null = event.currentTarget;

        if (target !== null) {
            let targetId = target.getAttribute('data-id');

            if (targetId !== null) {
                selectInput.current.setAttribute('data-id', targetId);

                selectInput.current.value = target.innerHTML;

                hideList(null, true);

                if (action) {
                    if (actionKey !== null) {
                        action(targetId, actionKey);
                    } else {
                        action(targetId);
                    }
                }
            }
        }
    }
    
    return (
        <div className={(className ?? '') + " bg-gray-700 select-box-wrapper relative hover:cursor-pointer"}>
            <IconInput
                icon={<i className="fa-solid fa-caret-down text-xs text-gray-400"></i>}
                reference={selectInput}
                type='text'
                iconPosition="right"
                wrapperClassName="w-full justify-between"
                iconWrapperClassName="p-1 rounded-r-md pr-2 "
                inputClassName="placeholder-gray-300 bg-gray-700 text-gray-300 p-1 pl-2 cursor-pointer rounded-md text-sm outline-0"
                placeholder={placeholder}
                onKeyPress={search}
                onBlur={(event: React.FocusEvent) => {hideList(event)}}
                onFocus={showList}
            />
            <div ref={selectBoxList} className="z-50 max-h-45 w-full bg-gray-700 mt-1 rounded-md select-box-list hidden absolute">
                {listArray.map((item, index) => (
                    <span onClick={selectItem} key={"select-box-option" + (index + 1)} className="block cursor-pointer p-2 text-sm text-gray-300 hover:bg-gray-600 rounded-md" data-id={item.id} tabIndex={-1}>{item.name}</span>
                ))}
            </div>
        </div>
    );
};