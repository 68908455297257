import { Parts } from "Kits/Infrastructure/services/Parts";
import {useEffect, useRef, useState } from "react";
import {Button} from "../../../../../../LoaderUI";
import {CreateKitModalRow} from "./CreateKitModalRow";
import {PartDetails} from "./PartDetails";
import {PartSearch} from "../../../Parts/PartSearch";

type Props = {
    className: string,
    showNewPartForm: boolean
    updateParts: Function
    attachedParts: Part[]
}

export const AttachedParts = ({className, showNewPartForm, updateParts, attachedParts}: Props) => {
    const [showPartComponent, setShowPartComponent] = useState({
        findPart: false,
        newPart: false,
    });

    const addPartsButton = useRef({} as HTMLInputElement);

    const findPartsRef = useRef({} as HTMLInputElement);

    const tableBodyHeight = {
        height: "450px",
    };

    const newPart = () => {
        setShowPartComponent({
            newPart: true,
            findPart: false,
        })
    }

    const findParts = () => {
        setShowPartComponent({
            newPart: false,
            findPart: true,
        })
    }

    const closePartComponents = () => {
         setShowPartComponent({
            newPart: false,
            findPart: false,
        })
    }

    const updateAttachedParts = (part: Part, remove: boolean = false) => {
        if (remove) {
            let existingObject = attachedParts.find(attachedPartsItem => attachedPartsItem.id === part.id);
            let index = attachedParts.indexOf(existingObject as Part);

            let newAttachedParts = [...attachedParts]

            newAttachedParts.splice(index, 1);

            updateParts(newAttachedParts);
        } else {
            updateParts([...attachedParts, part]);
        }
    }

    const quantityChange = (quantity: number, index: number) => {
        let currentAttachedParts = [...attachedParts];

        currentAttachedParts[index].quantity = quantity;

        updateParts(currentAttachedParts);
    }

    return (
        <div id="attached-parts" className={"h-full " + className}>
            <div id="attached-parts-header">
                <h1 className="text-xl">Attached Parts Detail</h1>
                <p className="text-base mt-2 text-gray-700">Add Existing Parts or Create new parts</p>
            </div>
            <PartDetails className={showPartComponent.newPart ? "" : " hidden pb-6"} close={closePartComponents} updateAttachedParts={updateAttachedParts}/>
            <PartSearch className={showPartComponent.findPart ? "" : " hidden pb-6"} close={closePartComponents}  updateAttachedParts={updateAttachedParts} attachedParts={attachedParts}/>
            <div className="flex">
                <Button
                    className={(showPartComponent.findPart || showPartComponent.newPart ? "hidden" : "") + " cursor-pointer mr-auto mt-8 p-2 mb-3 mr-4 w-36 relative flex justify-center items-center border border-gray-300 rounded-md text-gray-200 bg-gray-800"}
                    action={newPart} reference={addPartsButton}>
                    <span className="text-center">New Part</span>
                </Button>
                <Button
                    className={(showPartComponent.newPart || showPartComponent.findPart ? "hidden" : "") + " cursor-pointer mr-auto mt-8 p-2 mb-3 mr-4 w-36 relative flex justify-center items-center border border-gray-300 rounded-md text-gray-200 bg-gray-800"}
                    action={findParts} reference={findPartsRef}>
                    <span className="text-center">Find Parts</span>
                </Button>
            </div>
            <table
                className={(showPartComponent.findPart || showPartComponent.newPart ? "hidden" : "") + " block relative table-fixed border-spacing-4 border-collapse text-sm bg-gray-100 w-full"}>
                <thead className="w-full block relative pr-4">
                    <tr className="block w-full pr-1">
                        <th className="w-1/6 inline-block font-medium p-3 text-gray-500 text-left text-xs uppercase">Name</th>
                        <th className="w-1/6 inline-block font-medium p-3 text-gray-500 text-center text-xs uppercase">Quantity</th>
                        <th className="w-1/6 inline-block font-medium p-3 text-gray-500 text-center text-xs uppercase">Space</th>
                        <th className="w-1/6 inline-block font-medium p-3 text-gray-500 text-center text-xs uppercase">Net Mat
                        </th>
                        <th className="w-1/6 inline-block font-medium p-3 text-gray-500 text-center text-xs uppercase">Discount</th>
                        <th className="w-1/6 inline-block font-medium p-3 text-gray-500 text-center text-xs uppercase">Total Net Mat</th>
                    </tr>
                </thead>
                <tbody style={tableBodyHeight}
                       className="block pr-4 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                        {attachedParts.map((part, index) => {
                            return (
                                <CreateKitModalRow key={"part" + index} part={part} index={index} removePart={updateAttachedParts} quantityChange={quantityChange}/>
                            );
                        })}
                </tbody>
            </table>
        </div>
    )
}