import {AlertTemplate} from "./AlertTemplate";
import React from "react";
import {createRoot} from "react-dom/client";

export class Alert {
    private static _alert: HTMLDivElement;

    static display(message: string, action?: Function, subMessage?: string) {
        const alertContainer = document.createElement('div');

        alertContainer.id = "alert-container"
        
        let root = createRoot(alertContainer);

        root.render(<AlertTemplate message={message} okAction={action} subMessage={subMessage}/>);

        this._alert = alertContainer;
        document.body.append(alertContainer);
    }

    static close() {
        document.body.removeChild(this._alert);
    }
}