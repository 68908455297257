import {LoaderAPIService} from "../../../core/Infrastructure/services/LoaderAPIService";
import {RequestFailed} from "../../../core/Domain/exceptions/RequestFailed";
import { Toast } from "LoaderUI/Toast";

export class Kits extends LoaderAPIService {
    private readonly kitsUrl: string;

    constructor() {
        super();
        this.kitsUrl = this.getBaseUrl() + 'kits'
    }

    /**
     * Creates a new Kit
     *
     * @param {Partial<Kit>}
     * @returns {Promise<Object | boolean>}
     */
    async createKit({name, description, partsIncluded}: Partial<Kit>): Promise<Object | boolean> {
        const requestOptions: Object = this.buildRequestOptions(
            'POST',
            this.getAuthHeader(),
            {
                'name': name,
                'description': description,
                'parts': partsIncluded,
            }
        );

        let response = false;

        try {
            await this.request(this.kitsUrl, requestOptions);

           response = true;
        } catch (error) {
            if (error instanceof RequestFailed) {
                Toast.error(error.message);
            }
        }

        return response
    }

    /**
     * Gets all Kits
     *
     * @returns {Promise<Array<Kit> | boolean>}
     */
    async fetchKits(name: string | null = null): Promise<Array<Kit> | boolean> {
        const requestOptions: Object = this.buildRequestOptions(
            'GET',
            this.getAuthHeader(),
        );

        let getParams = '';

        if (name !== null) {
            getParams = "/" + name;
        }

        return await this.request(this.kitsUrl, requestOptions, 200, 'data');
    }
}