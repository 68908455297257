import React from "react";
import {useEffect, useRef, useState} from "react";


type Props = {
    position: string,
    anchorPosition: DOMRect
    top: number,
    content: JSX.Element
}

export function PopupTemplate({position, anchorPosition, top, content}: Props): JSX.Element {
    const popupWrap = useRef<HTMLDivElement>({} as HTMLDivElement);
    const [leftPosition, setLeftPosition] = useState<number>();


    useEffect(() => {
        if (position !== 'right') {
            setLeftPosition(anchorPosition.left);
        } else {
            let offset = anchorPosition.left - popupWrap.current.offsetWidth;
            let anchorWidth = anchorPosition.width;

            setLeftPosition(offset + anchorWidth);
        }
    });

    return (
        <div ref={popupWrap} id="popup-wrapper" className="absolute overflow-hidden border rounded-md border-gray-300" style={{zIndex: 1000, top: top, left: leftPosition}}>
            <div id="popup-body">
                {content}
            </div>
        </div>
    );
};