import React, {useEffect, useRef} from "react";
import {Checkbox} from "../../../../LoaderUI/Checkbox";
import {Button} from "../../../../LoaderUI";

type Props = {
   quoteLine: QuoteLineTemplate,
   showDetail: Function,
   updateCart: Function
   isChecked: boolean
}

export const KitSearchRow = ({quoteLine, showDetail, updateCart, isChecked}: Props): JSX.Element => {
    const rowRef = useRef<HTMLInputElement>({} as HTMLInputElement);

    useEffect(() => {
        if (!isChecked && (rowRef.current).checked) {
            rowRef.current.checked = false;
        }
    });

    const clickedRow = (event: React.MouseEvent) => {
        let target = event.target as HTMLElement;

        if (
            (target.classList !== null && target.classList.contains('moreInfo'))
            || (target.parentNode !== null && (target.parentNode as HTMLElement).classList.contains('moreInfo'))
            || ((target.firstElementChild !== null && target.firstElementChild !== undefined && typeof target.firstElementChild === 'object') && (target.firstChild as HTMLElement).classList.contains('moreInfo'))
        ) {
            return false;
        }

        if (!rowRef.current.checked && (target.tagName !== "INPUT" && target.id !== ("rowCheckbox" + quoteLine.id))) {
            rowRef.current.checked = true;
            rowRef.current.setAttribute("checked", "checked");
        } else if (rowRef.current.checked && (target.tagName !== "INPUT" && target.id !== ("rowCheckbox" + quoteLine.id))) {
            rowRef.current.checked = false;
            rowRef.current.removeAttribute("checked");
        }

        let isChecked = !rowRef.current.checked;

        updateCart(quoteLine, isChecked);
    }

    return (
        <tr onClick={clickedRow} className={"w-full table relative delay-75 hover:bg-gray-200 text-sm text-gray-800 h-4 hover:cursor-pointer"}>
            <td className="absolute left-5 pt-0.5 top-1/4">
                <Checkbox checked={isChecked} id={"rowCheckbox" + quoteLine.id} reference={rowRef} />
            </td>
            <td className={"w-3/6 pl-14 p-3 "}>
                {quoteLine.name}
            </td>
            <td className="w-1/6 p-3 text-center">
                {quoteLine.type}
            </td>
            <td className="w-1/6 p-3 text-center">
                {""}
            </td>
            <td className="w-1/6 p-3 text-center">
            </td>
            <td className="absolute right-5 moreInfo pt-0.5 top-1/4">
                 <Button className="moreInfo" action={() => {showDetail(quoteLine)}}>
                    <i className="fa-solid fa-circle-info text-lg"></i>
                </Button>
            </td>
        </tr>
    );
}