import React, {RefObject, useEffect, useState} from "react";

type Props = {
    icon: React.ReactNode,
    reference: RefObject<HTMLInputElement>,
    type: string,
    iconPosition?: string,
    value?: string,
    defaultValue?: string | number,
    label?: string,
    wrapperClassName?: string,
    iconWrapperClassName?: string,
    fullWrapperClassName?: string,
    inputClassName?: string,
    labelClassName?: string,
    placeholder?: string,
    onKeyPress?: Function | null,
    onChange?: Function | null,
    onBlur?: Function | null,
    onFocus?: Function | null,
    disabled?: boolean,
    allowNegatives?: boolean,
    max?: number | null,
    id?: string | null,
}

 type ValueProps = {
    defaultValue: string | number | undefined,
    value: string | number | undefined
 }

export const IconInput = ({
  icon,
  reference,
  type,
  label,
  wrapperClassName,
  iconWrapperClassName,
  fullWrapperClassName,
  inputClassName,
  labelClassName,
  placeholder = "",
  iconPosition = "left",
  onKeyPress = null,
  onChange = null,
  onBlur = null,
  onFocus = null,
  defaultValue,
  value,
  disabled = false,
  allowNegatives = false,
  max,
  id,
}: Props) => {
    const [values, setValues] = useState({
        defaultValue: defaultValue,
        value: value
    } as ValueProps);

    const [savedEvent, setSavedEvent] = useState(null as React.KeyboardEvent | null);

    useEffect(() => {
        setValues({
           defaultValue: defaultValue,
           value: value
        })

        if (savedEvent) {
            const timer = setTimeout(() => {
                if (onChange) {
                    onChange(savedEvent);
                }

                if (onKeyPress) {
                    onKeyPress(savedEvent);
                }

                setSavedEvent(null);
            }, 1000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [defaultValue, value, savedEvent])

    const onChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
       if (onChange !== null) {
           onChange(event);
       }
    }

    const onBlurEvent = (event: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur !== null) {
            onBlur(event);
        }
    }

    const onKeyPressEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
        let keyCode = event.key;
        let inputValue = (event.target as HTMLInputElement).value;

        if (type === 'currency' || type === 'number') {
            let regexValidation = /[0-9]|Backspace|\.|TAB|Tab|ArrowUp|ArrowDown|ArrowRight|ArrowLeft/;
            if (!regexValidation.test(keyCode) || (max && parseInt(inputValue + keyCode as string) > max)) {
                event.preventDefault();
                return false;
            }

            if (keyCode === "ArrowUp") {
                let newValue = values.value !== undefined && true ? Number(inputValue ?? 0) + 1 : undefined;
                let newDefaultValue = values.defaultValue !== undefined && true ? Number(inputValue ?? 0) + 1 : undefined;

                if (newDefaultValue === undefined && newValue === undefined) {
                    newDefaultValue = Number(inputValue ?? 0) + 1;
                }

                setValues({
                    defaultValue: defaultValue,
                    value: value
                });

                (event.target as HTMLInputElement).value = newDefaultValue !== undefined && true ? String(newDefaultValue) : String(newValue);

            } else if (keyCode === "ArrowDown") {
                let newValue = values.value !== undefined && true ? Number(inputValue ?? 0) - 1 : undefined;
                let newDefaultValue = values.defaultValue !== undefined && true ? Number(inputValue ?? 0) - 1 : undefined;

                if (newDefaultValue === undefined && newValue === undefined) {
                    newDefaultValue = Number(inputValue ?? 0) - 1;
                }

                if ((max && ((newDefaultValue ?? 0) > max || (newValue ?? 0) > max))
                    || (!allowNegatives && ((newDefaultValue ?? 0) < 0 || (newValue ?? 0) < 0))) {
                    event.preventDefault();
                    return false;
                }

                setValues({
                    defaultValue: newDefaultValue,
                    value: newValue
                });

                (event.target as HTMLInputElement).value = newDefaultValue !== undefined && true ? String(newDefaultValue) : String(newValue);
            }
        }

        setSavedEvent(event);
    }

    const onFocusEvent = (event: React.FocusEvent<HTMLInputElement>) => {
        if (onFocus !== null) {
            onFocus(event);
        }
    }

    let defaultValueProps = {};
    let valueProps = {};

    if (defaultValue) {
        let inputValue = (defaultValue ?? 0) == 0 ? 0 : defaultValue;

       defaultValueProps = {
           defaultValue: inputValue
       }

       valueProps = {}
    } else {
       defaultValueProps = {}

       valueProps = {
           value: value
       }
    }

    return (
        <div tabIndex={0} className={"icon-input-wrapper w-full relative " + fullWrapperClassName} onBlur={onBlurEvent} onFocus={onFocusEvent}>
            {label === undefined ? '' : <label className={labelClassName ?? ''}>{label}</label> }
            <div className={wrapperClassName + " flex input-wrapper"}>
                <div className={iconWrapperClassName + "  h-full " + (iconPosition === "left" ? "" : " order-last")}>
                    {icon}
                </div>
                <input
                    id={id ?? ''}
                    min={allowNegatives ? "" : 0}
                    max={max !== null ? max : ""}
                    onKeyDown={onKeyPressEvent}
                    placeholder={placeholder}
                    className={(inputClassName ?? '')
                    + (iconPosition === "left" ? " rounded-y-md rounded-r-md rounded-l-none"
                    : " rounded-r-none")
                    }
                    ref={reference}
                    type={type === 'currency' || type === 'number' ? 'text' : type}
                    {...valueProps}
                    {...defaultValueProps}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}