import React from "react";
import {PopupTemplate} from "./PopupTemplate";
import {createRoot} from "react-dom/client";

export class Popup {
    static popup: HTMLDivElement;
    
    static open(anchor: Element, content: JSX.Element, position: string = 'left') {
        const anchorPosition = anchor.getBoundingClientRect();
        const popupContainer = document.createElement('div');

        popupContainer.id = "popup-container";

        popupContainer.addEventListener('click', (event: MouseEvent) => {
            let target: EventTarget | null = event.target;
            
            if (target !== null) {
                let element = (target as Element);

                let dataId: string | null = element.id;
                
                if (dataId === 'popup-container') {
                    this.close();
                }    
            }
        });

        popupContainer.className = "absolute top-0 w-full h-screen";

        popupContainer.style.zIndex = "1000";

        let root = createRoot(popupContainer);

        root.render(<PopupTemplate top={anchorPosition.bottom + 5} anchorPosition={anchorPosition}
                                   position={position}
                                   content={content}/>);

        this.popup = popupContainer;
        document.body.append(popupContainer);
    }

    static close() {
        document.body.removeChild(this.popup);
    }
}