import { useRef } from "react";
import {Input} from "../../../../LoaderUI";
import {DurationPicker} from "../../../../LoaderUI/DurationPicker";

type Props = {
    quoteLine: QuoteLine
    updateQuoteLine: Function,
    part: QuoteLine,
    updateKit: Function
}

export const QuoteLinePart = ({quoteLine, updateQuoteLine, part, updateKit}: Props) => {
    const nameRef = useRef({} as HTMLInputElement);
    const descriptionRef = useRef({} as HTMLInputElement);
    const spaceRef = useRef({} as HTMLInputElement);
    const fullLoadCurrentRef = useRef({} as HTMLInputElement);
    const netMaterialsRef = useRef({} as HTMLInputElement);
    const quantityRef = useRef({} as HTMLInputElement);
    const discountRef = useRef({} as HTMLInputElement);
    const listPriceRef = useRef({} as HTMLInputElement);

    let hours = part.installHours.hours * part.quantity;
    let minutes = part.installHours.minutes * part.quantity;
    let seconds = (part.installHours.seconds ?? 0) * part.quantity

    if (seconds >= 60) {
        let incrementMinutes = (seconds / 60).toFixed(0);

        minutes = minutes + Number(incrementMinutes);
        seconds = seconds % 60;
        }

        if (minutes >= 60) {
        let incrementHours = (minutes / 60).toFixed(0);

        hours = hours + Number(incrementHours);
        minutes = minutes % 60;
    }

    const updatePart = (timeValues?: DurationDetails) => {
        let spaceValue = spaceRef.current.value;
        let fullLoadCurrentValue = fullLoadCurrentRef.current.value;
        let netMaterialsValue = netMaterialsRef.current.value;
        let quantityValue = quantityRef.current.value;
        let discountValue = discountRef.current.value;
        let nameValue = nameRef.current.value;

        part.name = nameValue;
        part.space = parseFloat(spaceValue);
        part.fullLoadCurrent = parseFloat(fullLoadCurrentValue);
        part.netMaterials = parseFloat(netMaterialsValue);
        part.quantity = parseInt(quantityValue);
        let listPrice = (parseFloat(netMaterialsValue) - (parseFloat(discountValue) / 100 * parseFloat(netMaterialsValue))) * parseInt(quantityValue);

        part.listPrice = parseFloat(listPrice.toFixed(2));
        part.discountRate = parseFloat(discountValue)

        listPriceRef.current.value = String(listPrice.toFixed(2));

        if (timeValues && (timeValues.hasOwnProperty('hours'))) {
            if ((timeValues.seconds ?? 0) >= 60) {
               let incrementMinutes = ((timeValues.seconds ?? 0) / 60).toFixed(0);

               timeValues.minutes = timeValues.minutes + Number(incrementMinutes);

               timeValues.seconds = ((timeValues.seconds ?? 0) % 60);
            }
            
            if (timeValues.minutes >= 60) {
               let incrementHours = (timeValues.minutes / 60).toFixed(0);

               timeValues.hours = timeValues.hours + Number(incrementHours);

               timeValues.minutes = (timeValues.minutes % 60);
            }

            let totalHours = Number(timeValues.hours) * part.quantity;
            let totalMinutes = Number(timeValues.minutes) * part.quantity;
            let totalSeconds = Number(timeValues.seconds) * part.quantity;

            if (totalSeconds >= 60) {
                let incrementMinutes = (totalSeconds / 60).toFixed(0);

                totalMinutes = totalMinutes + Number(incrementMinutes);
                totalSeconds = totalSeconds % 60;
            }

            if (totalMinutes >= 60) {
                let incrementHours = (totalMinutes / 60).toFixed(0);

                totalHours = totalHours + Number(incrementHours);
                totalMinutes = totalMinutes % 60;
            }

            hours = totalHours;
            minutes = totalMinutes;
            seconds = (totalSeconds ?? 0);

            part.installHours.hours = timeValues.hours;
            part.installHours.minutes = timeValues.minutes;
            part.installHours.seconds = timeValues.seconds;
        }

        updateKit();
    }

    return (
        <tr
            className="bg-gray-100 mb-1 rounded-md table border-spacing-3 relative p-4 hover:bg-gray-200 text-sm text-gray-800 shadow-md hover:cursor-pointer" style={{width: "96%"}}>
            <td className="w-2/12 inline-block font-medium py-5 pl-6 text-gray-500 text-left text-xs uppercase align-middle">
                <Input onChange={updatePart} inputClassName="w-full h-full bg-inherit outline-none text-left overflow-hidden text-ellipsis whitespace-nowrap" reference={nameRef} type="text" defaultValue={String(part.name)} />
            </td>
            <td className="w-1/12  inline-block font-medium text-gray-500 text-center text-xs uppercase align-middle">
                <Input onChange={updatePart} inputClassName="w-full h-full bg-inherit outline-none py-3 text-center" reference={quantityRef} type="number" defaultValue={String(part.quantity)} />
            </td>
            <td className="w-1/12  inline-block  overflow-hidden text-ellipsis whitespace-nowrap font-medium pl-6 text-gray-500 text-center text-xs uppercase align-middle">
                <DurationPicker onChange={updatePart} defaultValue={part.installHours} showTimeLabels={false} inputLabelClassName="text-xs mb-2 text-center w-full block" inputWrapClassName="relative" timeWrapClassName="gap-2" inputClassName=" w-full bg-inherit text-center outline-none w-full py-2 rounded-md appearance-none" includeSeconds={false} />
            </td>
            <td className="w-1/12  inline-block font-medium text-gray-500 text-center text-xs uppercase align-middle">
                <Input onChange={updatePart} inputClassName="w-full h-full bg-inherit outline-none py-3 text-center" reference={spaceRef} type="number" defaultValue={String(part.space)} />
            </td>
            <td className="w-1/12  inline-block font-medium text-gray-500 text-center text-xs uppercase align-middle">
                <Input onChange={updatePart} inputClassName="w-full h-full bg-inherit outline-none py-3 text-center" reference={fullLoadCurrentRef} type="number" defaultValue={String(part.fullLoadCurrent)} />
            </td>
            <td className="w-1/12  inline-block font-medium text-gray-500 text-center text-xs uppercase align-middle">
                <Input onChange={updatePart} inputClassName="w-full h-full bg-inherit outline-none py-3 text-center" reference={netMaterialsRef} type="currency" defaultValue={String(part.netMaterials)} />
            </td>

            <td className="w-1/12  inline-block font-medium text-gray-500 text-center text-xs uppercase align-middle">
                <Input onChange={updatePart} inputClassName="w-full h-full bg-inherit outline-none py-3 text-center" reference={discountRef} type="number" defaultValue={String(part.discountRate)} />
            </td>
            <td className="w-1/12  inline-block font-medium text-gray-500 text-center text-xs uppercase align-middle">
                {(part.space ?? 0) * part.quantity}
            </td>
            <td className="w-1/12  inline-block font-medium text-gray-500 text-center text-xs uppercase align-middle">
                {(part.fullLoadCurrent ?? 0) * part.quantity}
            </td>
            <td className="w-1/12  inline-block font-medium text-gray-500 text-center text-xs uppercase align-middle">
                {(hours < 10 ? ("0" + hours) : hours) + ":" + (minutes < 10 ? ("0" + minutes) : minutes)}
            </td>
            <td className="w-1/12 inline-block font-medium text-gray-500 text-center text-xs uppercase align-middle">
                <Input disabled={true} inputClassName="w-full h-full bg-inherit outline-none py-3 text-center" reference={listPriceRef} type="currency" defaultValue={String(part.listPrice)} />
            </td>
            <td onClick={() => {updateQuoteLine(quoteLine, true, part)}} className="absolute -right-10 h-full top-0">
                <div className="flex items-center justify-center h-full w-8 bg-gray-600 rounded-md">
                    <i  className="text-base fa-solid fa-xmark text-gray-200"></i>
                </div>
            </td>
        </tr>
    );
}