import React from 'react';

type Props = {
    reference: React.Ref<HTMLInputElement>,
    checked?: boolean,
    className?: string,
    id?: string,
}

export const Checkbox = ({reference, id, className, checked}: Props): JSX.Element => {
    return (
        <div className="form-check">
            <input
                defaultChecked={checked ?? false}
                id={id ?? ""}
                className={(className ?? "") + " form-check-input h-4 w-4 border border-gray-100 rounded-sm accent-gray-700 focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"}
                type="checkbox" value=""
                ref={reference}
            />
        </div>
    )
}