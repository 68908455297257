import {SideBar} from "core/Presentation/components/SideBar";
import React from "react";

export const Dashboard = () => {
    return (
        <div className="h-screen flex">
            <SideBar/>
            <div className="h-screen w-11/12">
                <h1>Hi!</h1>
                <p>You're logged in with React & JWT!!</p>
                <h3>Users from secure api end point:</h3>
            </div>
        </div>

    );
}