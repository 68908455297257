import {Button, Input} from "../../../../LoaderUI";

type Props = {
   quoteLine: QuoteLineTemplate,
   closeDetail: Function
}

export const KitSearchDetail = ({quoteLine, closeDetail}: Props) => {
    const header = quoteLine.type.charAt(0).toUpperCase() + quoteLine.type.slice(1);

    return (
       <div id="kit-search-detail" className="w-5/12 p-4 bg-gray-100 shadow-md rounded rounded-md border-l border-gray-300 relative">
           <Button action={() => {closeDetail()}} id="closeDetail" className="absolute right-8 flex items-center cursor-pointer">
               <i className="fa-solid fa-arrow-down-left text-sm align-middle"></i>
               <span className="text-sm ml-2 text-gray-600">Hide Details</span>
           </Button>
           <span id="detail-header" className="text-gray-800 text-md block">{header + " Detail"} </span>
           <span id="detail-name" className="text-xl font-medium text-gray-900 block mt-4">{quoteLine.name}</span>
           <p id="detail-description" className="text-sm block mt-2 mb-5">{quoteLine.description}</p>
           <table id="detail-specs" className="table-auto w-full mt-2 border-t border-gray-300">
               <thead>
                   <tr>
                       <th className="text-gray-900 font-light text-sm p-3">Net Materials</th>
                       <th className="text-gray-900 font-light text-sm p-3">Space</th>
                       <th className="text-gray-900 font-light text-sm p-3">FLC</th>
                       <th className="text-gray-900 font-light text-sm p-3">Total Hours</th>
                   </tr>
               </thead>
               <tbody>
                   <tr>
                       <td className="text-center">{quoteLine.netMaterials}</td>
                       <td className="text-center">{quoteLine.space}</td>
                       <td className="text-center">{quoteLine.fullLoadCurrent}</td>
                       <td className="text-center"></td>
                   </tr>
               </tbody>
           </table>
       </div>
    )
}