import {QuoteLineRow} from "./QuoteLineRow";

type Props = {
    quoteLines: QuoteLine[]
    updateQuoteLine: Function
}

export const QuoteLinesTable = ({quoteLines, updateQuoteLine}: Props) => {
    const tableBodyHeight = {
        height: "570px",
    };

   return (
      <div id="quote-kits-parts" className="relative h-4/6">
          <div className="quote-line-table-wrap h-full relative">
              <table id="quote-lines-table" className="block bg-gray-200 h-full relative table-fixed border-spacing-4 border-collapse text-sm bg-gray-100 w-full">
                  <thead className="w-11/12 block relative bg-gray-200 mr-auto ml-auto">
                      <tr className="block w-full py-4 pr-2">
                            <th className="w-2/12 inline-block font-medium  pl-6 text-gray-500 text-left text-xs uppercase">Name</th>
                            <th className="w-1/12 inline-block font-medium  text-gray-500 text-center text-xs uppercase">Qty</th>
                            <th className="w-1/12 inline-block font-medium  text-gray-500 text-center text-xs uppercase">Hours</th>
                            <th className="w-1/12 inline-block font-medium  text-gray-500 text-center text-xs uppercase">Space</th>
                            <th className="w-1/12 inline-block font-medium  text-gray-500 text-center text-xs uppercase">FLC</th>
                            <th className="w-1/12 inline-block font-medium  text-gray-500 text-center text-xs uppercase">Net Mat</th>
                            <th className="w-1/12 inline-block font-medium  text-gray-500 text-center text-xs uppercase">Discount</th>
                            <th className="w-1/12 inline-block font-medium text-gray-500 text-center text-xs uppercase">T-Space</th>
                            <th className="w-1/12 inline-block font-medium text-gray-500 text-center text-xs uppercase">T-FLC</th>
                            <th className="w-1/12 inline-block font-medium text-gray-500 text-center text-xs uppercase">T-Hours</th>
                            <th className="w-1/12 inline-block font-medium text-gray-500 text-center text-xs uppercase">T-Net Mat</th>
                      </tr>
                  </thead>
                  <tbody style={tableBodyHeight} className="bg-gray-200 relative block overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-200 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                      {quoteLines.map((quoteLine, index) => {
                          return (
                              <QuoteLineRow key={"quoteLine" + index + "-" + quoteLine.id} updateQuoteLine={updateQuoteLine} quoteLine={quoteLine} index={index} />
                          );
                      })}
                  </tbody>
              </table>
          </div>
      </div>
   )
}