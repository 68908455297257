import {KitSearchRow} from "./KitSearchRow";

type Props = {
   results: QuoteLineTemplate[]
   showDetail: Function
   updateCart: Function
   currentCart: QuoteLineTemplate[]
   width: string
}

export const KitSearchTable = ({results, showDetail, updateCart, currentCart, width}: Props)  => {
    return (
       <div id="kit-search-wrap" className={"flex relative h-full " + width}>
           <table id="kit-search-table" className="h-full relative table-fixed border-collapse text-sm bg-gray-100 w-full">
               <thead className="bg-gray-200 w-full relative">
                   <tr className="block w-full">
                        <th className="w-3/6 pl-14 inline-block font-medium p-3 text-gray-500 text-left text-xs uppercase">Name</th>
                        <th className="w-1/6 inline-block font-medium p-3 text-gray-500 text-center text-xs uppercase">Type</th>
                        <th className="w-1/6 inline-block font-medium p-3 text-gray-500 text-center text-xs uppercase">Supplier</th>
                        <th className="w-1/6 inline-block font-medium p-3 text-gray-500 text-center text-xs uppercase">Total Hours</th>
                  </tr>
               </thead>
               <tbody id="kit-search-table-body" className="h-full relative block overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                    {results.map((quoteLine: QuoteLineTemplate, index: number) => {
                        let inCart = currentCart.find(cartItem => cartItem.id === quoteLine.id);

                            return (
                                <KitSearchRow
                                    key={"kit-search-row-" + index}
                                    updateCart={updateCart}
                                    isChecked={inCart !== undefined}
                                    showDetail={showDetail}
                                    quoteLine={quoteLine}
                                />
                            )
                        }
                    )}
               </tbody>
           </table>
       </div>
    )
}