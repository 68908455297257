import { Parts } from "Kits/Infrastructure/services/Parts";
import { Button } from "LoaderUI/Button";
import { SearchBox } from "LoaderUI/SearchBox";
import {useEffect, useRef, useState} from "react";
import {PartSearchTable} from "./PartSearchTable";

type Props = {
   className: string,
   close: Function,
   attachedParts: Part[],
   updateAttachedParts: Function,
}

export const PartSearch = ({className, close, attachedParts, updateAttachedParts}: Props) => {
    const [results, setResults] = useState([] as Part[]);

    const searchRef = useRef({} as HTMLInputElement);
    const closeButton = useRef({} as HTMLInputElement);

    useEffect(() => {
        console.log('part search');
    })

    const findItems = (keyword: string | null = null) => {
        let partService = new Parts();

        if (keyword === null && (searchRef.current.value !== "")) {
           keyword = searchRef.current.value;
        }

        partService.fetchParts(keyword).then(response => {
            setResults(response as Part[]);
        });
    }

    const updateParts = (part: Part) => {
        updateAttachedParts(part);
    }

    return (
       <div id="part-search" className={"h-full relative " + className}>
            <div id="part-search-header" className="p-2">
                <div className="flex">
                  <SearchBox
                      action={findItems}
                       searchBoxClass="rounded-md w-full bg-gray-200 w-full outline-0 text-gray-900 placeholder-gray-500"
                       reference={searchRef}
                       wrapperClass="w-full bg-gray-200 border border-gray-400 w-5/6 p-2 mt-4 rounded-md"
                       placeholder="Search Kits and Parts by name or category.."
                       autoSearch={false}
                   />
                   <Button action={() => findItems()} className="bg-gray-800 ml-2 mt-4 flex items-center rounded-md text-sm py-1 px-4 hover:cursor-pointer">
                       <span className="text-gray-200">Search</span>
                   </Button>
                </div>
            </div>
            <div id="part-search-body" className="flex h-4/6 relative">
                <PartSearchTable attachedParts={attachedParts} updateAttachedParts={updateParts} results={results}/>
            </div>
           <div className="flex mt-6 ml-auto w-3/6">
                    <Button
                        className="cursor-pointer ml-auto p-2 mb-3 mr-4 w-36 relative flex justify-center items-center border border-gray-300 rounded-md text-gray-100 bg-gray-500"
                        action={() => {
                            close(false)
                        }} reference={closeButton}>
                        <span className="text-center">Cancel Search</span>
                    </Button>
            </div>
        </div>
    );
}