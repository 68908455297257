import React from 'react';
import {ModalTemplate} from "./ModalTemplate";
import {Alert} from "../Alert";
import {createRoot} from "react-dom/client";

export class Modal {
    private static modal: HTMLDivElement;
    private static loading: HTMLDivElement;
    
    static startLoading() {
        const loadingDiv: HTMLDivElement = document.createElement('div');
        const loadingIcon: HTMLElement = document.createElement('i');
        loadingIcon.className = 'fa-solid fa-spinner-third';
        loadingDiv.id = 'loading-container';
        loadingDiv.append(loadingIcon);
        
        this.loading = loadingDiv;
        document.body.append(loadingDiv);
    }

    static stopLoading() {
        document.body.removeChild(this.loading);
    }

    /**
     * Opens a modal dialog in the center of the screen
     *
     * @param content - JSX Element with content
     * @param autoClose - boolean value indicating whether to auto close clicking outside the modal
     * @param width - Number between 0-100 indicating the percentage of the screen width
     * @param height - Number between 0-100 indicating the percentage of the screen height
     */
    static open(content: JSX.Element, autoClose: boolean = true, width: number = 80, height: number = 80) {
        const modalContainer = document.createElement('div');

        if (autoClose) {
            modalContainer.addEventListener('click', (event: MouseEvent) => {
                const target: EventTarget | null = event.target;
                
                if (target !== null) {
                    if ((target as Element).id === 'modal-container') {
                        Alert.display('Are you sure you want to close this modal?', this.close, 'Your changes will be lost');
                    }
                }
            });
        }

        modalContainer.className = "w-full h-full absolute top-0 right-0 left-0 grid place-items-center  z-50"
        modalContainer.id = "modal-container"

        let root = createRoot(modalContainer);

        this.greyOutBackground();

        root.render(<ModalTemplate content={content} width={width} height={height} />);

        this.modal = modalContainer;
        document.body.append(modalContainer);
    }

    /**
     * Adds a grey background to the HTML root element using opacity
     */
    static greyOutBackground() {
        let root: HTMLElement = document.getElementById('root') as HTMLElement;

        root.classList.add('opacity-50');
    }

    /**
     * Removes grey background from HTML root element.
     */
    static removeGreyOutBackground() {
         let root: HTMLElement = document.getElementById('root') as HTMLElement;


         root.classList.remove('opacity-50');
    }

    /**
     * Closes currently open modal
     */
    static close() {
        this.removeGreyOutBackground();

        document.body.removeChild(this.modal);
    }

    static refresh() {
        console.log("Refreshed Modal");
    }
}