import React from "react";
import {ToastTemplate} from "./ToastTemplate";
import {createRoot, Root} from "react-dom/client";

export class Toast {
    private static toast: HTMLDivElement;
    private static readonly errorIcon: string = "fa-duotone fa-circle-exclamation";
    private static readonly errorColour: string = "#ea1601";

    private static readonly warningIcon: string = "fa-duotone fa-triangle-exclamation";
    private static readonly warningColour: string = "#fad202";

    private static readonly infoIcon: string = "fa-duotone fa-circle-info";
    private static readonly infoColour: string = "#0075da";

    private static readonly successIcon: string = "fa-duotone fa-check";
    private static readonly successColour: string = "#64bc26";

    static #toast(className: string, message: string, header: string, highlight: string) {
        const allToasts = document.getElementsByClassName('toast-container');
        if (allToasts.length >= 5) {
            let toastLength: number = (allToasts.length - 5);

            for (let toastCount: number = 0; toastCount <= toastLength; toastCount++) {
                this.slideOut(allToasts[toastCount]);
            }
        }

        const toastContainer: HTMLDivElement = document.createElement('div');
        toastContainer.className = 'toast-container';
        toastContainer.style.setProperty('right', '-46rem');
        toastContainer.setAttribute('toast-count', String(allToasts.length + 1));

        let root: Root = createRoot(toastContainer);

        root.render(<ToastTemplate header={header} highlight={highlight} icon={className} message={message}/>);
        
        document.body.appendChild(toastContainer);
        this.toast = toastContainer;
    }

    static slideIn() {
        const allToasts: HTMLCollectionOf<Element> = document.getElementsByClassName('toast-container');

        if (allToasts.length > 0) {
            const toastLength: number = allToasts.length;

            const toastPosition: number = toastLength === 1 ? 80 : 80 * toastLength;

            this.toast.style.bottom = toastPosition + 'px';
        }


        this.toast.style.right = "10px";
    }

    static slideOut(toast: Element) {
        let toastContainer: HTMLElement;
        
        let toastTarget: Element | null = toast;

        if (toastTarget !== null) {
            let element: HTMLElement = (toastTarget as HTMLElement);
            
            
            if (element.classList.contains('toast-container')) {
                toastContainer = element;
            } else {
                let closestElement: HTMLElement | null = element.closest('.toast-container');

                toastContainer = closestElement ?? {} as HTMLElement;
            }
            
            const nextSibling: HTMLElement = (toastContainer.nextSibling as HTMLElement);

            if (nextSibling !== null && nextSibling.classList.contains('toast-container')) {
                nextSibling.style.bottom = toastContainer.style.bottom;
            }

            toastContainer.style.right = "-46rem";

            setTimeout(() => {
                if (toastContainer !== null && toastContainer !== undefined && document.body.contains(toastContainer)) {
                    document.body.removeChild(toastContainer);
                }
            }, 1000);    
        }
    }

    static error(message: string) {
        this.#toast(this.errorIcon, message, 'Error', this.errorColour);
    }

    static warning(message: string) {
        this.#toast(this.warningIcon, message, 'Warning', this.warningColour);
    }

    static info($message: string) {
        this.#toast(this.infoIcon, $message, 'Info', this.infoColour);
    }

    static success($message: string) {
        this.#toast(this.successIcon, $message, 'Success', this.successColour);
    }
}