import {PartSearchRow} from "./PartSearchRow";

type Props = {
   results: Part[]
   updateAttachedParts: Function
   attachedParts: Part[]
}

export const PartSearchTable = ({results, updateAttachedParts, attachedParts}: Props)  => {


    return (
       <div id="kit-search-wrap" className={"flex relative h-full "}>
           <table id="kit-search-table" className="h-full relative table-fixed border-collapse text-sm w-full">
               <thead className="w-full relative pr-4">
                   <tr className="block w-full">
                        <th className="w-3/12 pl-14 inline-block font-medium p-3 text-gray-500 text-left text-xs uppercase">Name</th>
                        <th className="w-3/12 inline-block font-medium p-3 text-gray-500 text-center text-xs uppercase">Install Hours</th>
                        <th className="w-2/12 inline-block font-medium p-3 text-gray-500 text-center text-xs uppercase">Net Materials</th>
                        <th className="w-2/12 inline-block font-medium p-3 text-gray-500 text-center text-xs uppercase">Discount Rate</th>
                        <th className="w-2/12 inline-block font-medium p-3 text-gray-500 text-center text-xs uppercase">Total Net Mat</th>
                  </tr>
               </thead>
               <tbody id="kit-search-table-body" className="h-full relative block pr-4 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                    {results.map((part: Part, index: number) => {
                        let inCart = attachedParts.find(attachedPartsItem => attachedPartsItem.id === part.id);
                            if (!inCart) {
                                return (
                                    <PartSearchRow
                                        key={"kit-search-row-" + index}
                                        updateAttachedParts={updateAttachedParts}
                                        part={part}
                                    />
                                );
                            } else {
                                return ("")
                            }
                        }
                    )}
               </tbody>
           </table>
       </div>
    )
}