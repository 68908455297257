import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import 'index.scss';
import {App} from './App';
import {Root} from "react-dom/client";

const root: Root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
