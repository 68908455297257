import {useRef} from "react";
import {Button, Input, Modal} from "../../../LoaderUI";
import {ContactService} from "../../Infrastructure/Services/ContactService";

type Props = {
    customerId: number
    onClose?: Function
}

export const CreateContact = ({customerId, onClose}: Props) => {
    const name = useRef({} as HTMLInputElement);
    const lastName = useRef({} as HTMLInputElement);
    const email = useRef({} as HTMLInputElement);
    const telephone = useRef({} as HTMLInputElement);
    const secondaryTelephone = useRef({} as HTMLInputElement);

    const createContact = () => {
        let customerService = new ContactService();

        let newContact = {
            firstName: name.current.value,
            lastName: lastName.current.value,
            email: email.current.value,
            primaryTelephoneNumber: telephone.current.value,
            secondaryTelephoneNumber: secondaryTelephone.current.value,
            customerId: customerId
        } as Partial<ContactDetails>

        customerService.createContact(newContact).then(() => {
            if (onClose) {
                onClose()
            }

            Modal.close();
        })
    }

    return (
        <div className="p-8 h-full relative">
             <div id="customer-creation-header">
                 <h1 className="text-xl">Contact Creation Details</h1>
                 <p className="text-base mt-2 text-gray-700">Fill out the details for your new Contact</p>
             </div>
            <div id="customer-creation-body">
                <Input
                    wrapperClassName="grid text-sm mt-8"
                    labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                    inputClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md"
                    reference={name}
                    label="FIRST NAME"
                    type="text"
                    disabled={false}
                    autoFocus={false}
                    placeholder=""
                />
                <Input
                    wrapperClassName="grid text-sm mt-8"
                    labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                    inputClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md"
                    reference={lastName}
                    label="LAST NAME"
                    type="text"
                    disabled={false}
                    autoFocus={false}
                    placeholder=""
                />
                <Input
                    wrapperClassName="grid text-sm mt-8"
                    labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                    inputClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md"
                    reference={telephone}
                    label="PRIMARY TELEPHONE"
                    type="number"
                    disabled={false}
                    autoFocus={false}
                    placeholder=""
                />
                <Input
                    wrapperClassName="grid text-sm mt-8"
                    labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                    inputClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md"
                    reference={secondaryTelephone}
                    label="SECONDARY TELEPHONE"
                    type="number"
                    disabled={false}
                    autoFocus={false}
                    placeholder=""
                />
                <Input
                    wrapperClassName="grid text-sm mt-8"
                    labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                    inputClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md"
                    reference={email}
                    label="EMAIL"
                    type="text"
                    disabled={false}
                    autoFocus={false}
                    placeholder=""
                />
            </div>
            <div id="create-customer-footer" className="flex absolute right-5 bottom-5  w-3/6">
                <Button action={() => {Modal.close()}} className="ml-4 p-3 w-32 bg-gray-800 text-center items-center rounded-md text-sm hover:cursor-pointer">
                    <span className="text-gray-200 text-md">Close</span>
                </Button>
                <Button action={createContact} className=" ml-4 p-3 w-40 bg-gray-800 text-center items-center rounded-md text-sm hover:cursor-pointer">
                    <span className="text-gray-200 text-md">Create Contact</span>
                </Button>
            </div>
        </div>
    )
}