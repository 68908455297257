import {SearchBox} from "../../../../LoaderUI/SearchBox";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {SelectBox} from "../../../../LoaderUI/SelectBox";
import {Button, Modal, Popup} from "../../../../LoaderUI";
import {KitSearchTable} from "./KitSearchTable";
import {QuoteLines} from "../../../Infrastructure/services/QuoteLine";
import {KitSearchDetail} from "./KitSearchDetail";
import {CartPopup} from "./CartPopup";

type Props = {
    quoteId: number;
    onClose?: Function
}

export const KitSearchModal = ({quoteId, onClose}: Props) => {
    const quoteLines = new QuoteLines();

    const searchRef = useRef({} as HTMLInputElement);
    const supplierRef = useRef({} as HTMLInputElement);
    const cartRef = useRef({} as HTMLInputElement);

    const [results, setResults] = useState([] as QuoteLineTemplate[])
    const [searchCriteria, setSearchCriteria] = useState({} as KitSearchCriteria);
    const [selectedDetail, setSelectedDetail] = useState({} as QuoteLineTemplate);
    const [cart, setCart] = useState([] as QuoteLineTemplate[]);
    const [loaded, setLoaded] = useState(false);
    const [suppliers, setSuppliers] = useState([]);

    const findItems = useCallback((search?: string) => {

        let searchValue = search ?? searchRef.current.value;

        quoteLines.fetchQuoteLineTemplates(searchValue).then(response => {
            let quoteLines: QuoteLineTemplate[] = (response ?? []) as QuoteLineTemplate[];

            setResults(quoteLines);
        });

        closeDetail();
    }, [])

    useEffect(() => {
        if (!loaded) {
            findItems()
            setLoaded(true);
        }
    }, [cart, findItems, loaded]);

    const addToSearchCriteria = (value: string | number, criteriaType: string) => {
        let currentCriteria: KitSearchCriteria = {...searchCriteria} as KitSearchCriteria;

        Object.defineProperty(currentCriteria, criteriaType, {value});

        setSearchCriteria(currentCriteria);
    }

    const updateCart = async (quoteLine: QuoteLineTemplate, remove: boolean = false) => {
        if (remove) {
            let existingObject = cart.find(cartItem => cartItem.id === quoteLine.id);
            let index = cart.indexOf(existingObject as QuoteLineTemplate);

            cart.splice(index, 1);

            setCart(cart);
        } else {
            quoteLine.quantity = 1;

            setCart([...cart, quoteLine]);
        }
    }

    const closeDetail = () => {
        setSelectedDetail({} as QuoteLineTemplate);
    }

    const quantityChange = (quantity: number, index: number) => {
        let currentCart = [...cart];

        currentCart[index].quantity = quantity;

        setCart(currentCart);
    }

    const showCart = () => {
        Popup.open(cartRef.current, <CartPopup updateCart={updateCart} overwriteCart={setCart} quoteLines={cart} quantityChange={quantityChange} />, 'right');
    }

    let kitSearchDetail;
    let tableWidth;

    if (Object.keys(selectedDetail).length > 0) {
        kitSearchDetail = <KitSearchDetail quoteLine={selectedDetail} closeDetail={closeDetail}/>
        tableWidth = "w-7/12"
    } else {
        kitSearchDetail = "";
        tableWidth = "w-full";
    }

    const addItemsToQuote = () => {
        let cartItems = cart;

        let kits = {};
        let parts = {};

        for (let i = 0; i < cartItems.length; i++) {
            let id = cartItems[i].id;

            if (cartItems[i].type === "kit") {
                kits = {...kits, [id]: cartItems[i].quantity}
            } else if (cartItems[i].type === 'part') {
                parts = {...parts, [id]: cartItems[i].quantity}
            }
        }

        Modal.startLoading();

        quoteLines.insertQuoteLines(quoteId, kits, parts).then(() => {
                Modal.stopLoading();

                if (onClose) {
                    onClose();
                }

                Modal.close();
            }
        )
    }

    return (
        <div id="kit-search" className="h-full relative">
            <div id="kit-search-header" className="p-8">
                <div className="flex">
                  <SearchBox
                      action={findItems}
                       searchBoxClass="rounded-md w-full bg-gray-200 w-full outline-0 text-gray-900 placeholder-gray-500"
                       reference={searchRef}
                       wrapperClass="w-full bg-gray-200 border border-gray-400 w-5/6 p-2 mt-4 rounded-md"
                       placeholder="Search Kits and Parts by name or category.."
                       autoSearch={false}
                   />
                   <Button action={() => findItems()} className="bg-gray-800 ml-2 mt-4 flex items-center rounded-md text-sm py-1 px-4 hover:cursor-pointer">
                       <span className="text-gray-200">Search</span>
                   </Button>
                   <Button reference={cartRef} action={() => {showCart()}} className="mt-5 ml-16 hover:cursor-pointer flex items-center">
                       <span className="fa-layers fa-fw text-3xl">
                           <i className="fa-solid fa-cart-flatbed-suitcase"></i>
                           <span className="fa-layers-counter fa-layers-top-right text-3xl">{cart.length}</span>
                       </span>
                   </Button>
                </div>
                <div id="kit-search-filters">
                   <SelectBox reference={supplierRef} list={suppliers} action={addToSearchCriteria} actionKey="supplier" placeholder="Select Suppliers.." className="mt-4 w-2/12 rounded-md" />
                </div>
            </div>
            <div id="kit-search-body" className="flex border border-gray-300 h-4/6 relative">
                <KitSearchTable currentCart={cart} updateCart={updateCart} showDetail={setSelectedDetail} results={results} width={tableWidth} />
                {kitSearchDetail}
            </div>
            <div id="kit-search-footer" className="w-full p-6 flex">
                <Button action={() => {Modal.close()}} className="ml-auto p-3 w-32 bg-gray-500 text-center items-center rounded-md text-sm hover:cursor-pointer">
                    <span className="text-gray-200 text-md">Cancel Search</span>
                </Button>
                <Button action={addItemsToQuote} className="ml-4 p-3 w-32 bg-gray-800 text-center items-center rounded-md text-sm hover:cursor-pointer">
                    <span className="text-gray-200 text-md">Add to Quote</span>
                </Button>
            </div>
        </div>
    )
}