import React from "react";
import {Button} from "../Button";
import {Alert} from "./index";

type Props = {
    message: string,
    subMessage?: string,
    okAction?: Function
}

export const AlertTemplate = ({message, subMessage, okAction}: Props): JSX.Element => {
    const cancel = () => {
        Alert.close();
    }
    
    const passThrough = () => {
        if (okAction !== undefined) {
            okAction(); 
        }
        
        Alert.close();
    }
    
    return (
        <div id="alert-wrapper">
            <div id="alert-header">
                <span className="message">{message}</span>
                <span className="sub-message">{subMessage ?? ''}</span>
            </div>
            <div id="alert-footer">
                <Button action={cancel} id="alert-cancel" className="alert-resolve">Cancel</Button>
                <Button action={passThrough} id="alert-ok" className="alert-resolve">Ok</Button>    
            </div>
        </div>
    )
}