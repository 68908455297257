import React from 'react';

type Props = {
    tabs: Array<string>,
    selected: string
    action?: Function,
    className?: string,
}

export const Tabs = ({ tabs, selected, action, className }: Props): JSX.Element => {
    return (
        <div className={className + " flex grid-flow-col auto-cols-max gap-x-4 p-2 px-4 cursor-pointer"}>
            {tabs.map((tab, index) => {
                return (<h1 onClick={() => {
                        if (action !== undefined && action !== null) { action(tab) }
                }} key={"tab_" + tab + "_" + index} className={"text-lg px-4 text-gray-600 tabs-tab font-light rounded-lg text-center" + (selected === tab ? " text-gray-900 bg-gray-400 " : "")}>{tab}</h1>
                )})}
        </div>
    )
}