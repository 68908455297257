import {useEffect, useRef, useState} from "react";
import {Parts} from "Kits/Infrastructure/services/Parts";
import React from "react";
import {PartRow} from "./PartRow";

export const PartsTable = (): JSX.Element => {
    const [parts, setParts] = useState<Part[]>([] as Part[]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const addPartButtons = useRef<HTMLTableRowElement>({} as HTMLTableRowElement);


    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
            const newParts = new Parts();

            newParts.fetchParts().then(response => {
                let responseArray = response as Array<Part>;
                let newParts = [] as Part[];

                if (response) {
                    newParts = responseArray.sort(function (a, b) {
                        let firstItem = a.id ?? 0;
                        let lastItem = b.id ?? 0;

                        return lastItem > firstItem ? 1 : -1;
                    });
                }

                setParts(newParts);
            });
        }
    }, [parts, loaded]);

    const addPart = () => {
        let activeNewParts = document.querySelectorAll(".new-part");

        if (activeNewParts.length === 0) {
            let newPart: Part = {
                name: '',
                description: '',
                fullLoadCurrent: 0,
                space: 0,
                netMaterials: 0,
                isNew: true,
            }

            setParts([newPart, ...parts]);
        } else {
            console.log('Finish your current part');
        }
    };

    const updateTableItem = (item: Part, index: number) => {
        let currentParts = [...parts];

        currentParts[index] = {
            ...currentParts[index],
            name: item.name,
            description: item.description,
            fullLoadCurrent: item.fullLoadCurrent,
            space: item.space,
            netMaterials: item.netMaterials,
            installHours: item.installHours,
            isNew: item.isNew,
        };

        setParts(currentParts);
    }

    return (
        <table className="border-collapse table-auto w-full text-sm bg-gray-100 shadow-sm rounded-md h-5/6">
            <thead className="bg-gray-200 rounded-md relative">
            {/*<tr className="absolute bottom-0 left-0 right-0" ref={addPartButtons}>
                <td className="absolute -top-5 left-1/2 transform -translate-x-1/2  bg-gray-600 rounded-full w-10 h-10 grid place-items-center">
                    <Button className="relative w-full h-full" action={addPart}>
                        <span
                            className="absolute text-3xl text-gray-300 inline-block align-top left-1/2 transform -translate-x-1/2">+</span>
                    </Button>
                </td>
            </tr>*/}
            <tr className="rounded-md">
                <th className="rounded-tl-md font-medium p-3 text-gray-500 text-left text-xs uppercase align-middle">Name</th>
                <th className="font-medium p-3 text-gray-500 text-left text-xs uppercase align-middle">Description</th>
                <th className="font-medium p-3 text-gray-500 text-center text-xs uppercase align-middle">FLC</th>
                <th className="font-medium p-3 text-gray-500 text-center text-xs uppercase align-middle">Space</th>
                <th className="font-medium p-3 text-gray-500 text-center text-xs uppercase align-middle">Net Mat</th>
                <th className="font-medium p-3 text-gray-500 text-center text-xs uppercase align-middle">Workshop Hours</th>
                <th className="font-medium p-3 text-gray-500 text-center text-xs uppercase align-middle">Software Hours</th>
                <th className="font-medium p-3 text-gray-500 text-center text-xs uppercase align-middle">Engineer Hours</th>
                <th className="rounded-tr-md font-medium p-3 text-gray-500 text-left text-xs uppercase align-middle">Total
                    Hours
                </th>
            </tr>
            </thead>
            <tbody id="part-table-body">
            {parts.map((part: Part, index: number) => {
                    return (
                        <PartRow
                            key={'part' + index}
                            name={part.name}
                            description={part.description}
                            fullLoadCurrent={part.fullLoadCurrent}
                            space={part.space}
                            netMaterials={part.netMaterials}
                            totalHours={part.installHours ?? {hours: 0, minutes: 0, seconds: 0}}
                            isNew={part.isNew as boolean}
                            index={index as number}
                            updateTableItem={updateTableItem as Function}
                        />
                    )
                }
            )}
            </tbody>
        </table>
    );
}