import {LoaderAPIService} from "../../../core/Infrastructure/services/LoaderAPIService";
import {RequestFailed} from "../../../core/Domain/exceptions/RequestFailed";
import {Toast} from "../../../LoaderUI";

export class CustomerService extends LoaderAPIService {
    private readonly customerUrl: string;

    constructor() {
        super();
        this.customerUrl = this.getBaseUrl() + 'customer'
    }

    /**
     * Creates a new Customer
     *
     * @param {Partial<Kit>}
     * @returns {Promise<Object | boolean>}
     */
    async createCustomer({name, email, telephone, website}: Partial<Customer>): Promise<Object | boolean> {
        const requestOptions: Object = this.buildRequestOptions(
            'POST',
            this.getAuthHeader(),
            {
                'name': name,
                'email': email,
                'telephone': telephone,
                'website': website,
            }
        );

        let response = false;

        try {
            await this.request(this.customerUrl, requestOptions);

            response = true;
        } catch (error) {
            if (error instanceof RequestFailed) {
                Toast.error(error.message);
            }
        }

        return response
    }

    async getCustomers(): Promise<Object | boolean> {
        const requestOptions: Object = this.buildRequestOptions(
            'GET',
            this.getAuthHeader()
        );

        return await this.request(this.customerUrl, requestOptions, 200, 'data');
    }
}
