import React from 'react';

type Props = {
    content: React.ReactNode
    width: number,
    height: number
}

export function ModalTemplate({content, width, height}: Props): JSX.Element {
    return (
        <div id="modal-body" style={{height: height + "%", width: width + "%"}} className="justify-center rounded-md bg-gray-100 shadow-xl">
            {content}
        </div>
    );
};