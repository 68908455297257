import React from 'react';
import {createRoot} from "react-dom/client";
import {SlideOutModalTemplate} from "./Templates/SlideOutModalTemplate";

export class SlideOutModal {
    private static modal: HTMLDivElement;

    static open(content: JSX.Element) {
        const modalContainer = document.createElement('div');

        modalContainer.id = "modal-container"

        modalContainer.className = "absolute top-0 overflow-hidden h-full w-full z-49";

        let root = createRoot(modalContainer);

        this.modal = modalContainer;

        this.greyOutBackground();

        root.render(<SlideOutModalTemplate content={content}/>);

        document.body.appendChild(modalContainer);
    }

    /**
     * Removes grey background from HTML root element.
     */
    static removeGreyOutBackground() {
        let root: HTMLElement = document.getElementById('root') as HTMLElement;


        root.classList.remove('opacity-50');
    }

    /**
     * Adds a grey background to the HTML root element using opacity
     */
    static greyOutBackground() {
        let root: HTMLElement = document.getElementById('root') as HTMLElement;

        root.classList.add('opacity-50');
    }

    static close() {
        this.removeGreyOutBackground();

        document.body.removeChild(this.modal);
    }

    static refresh() {
        console.log("Refreshed Modal");
    }
}