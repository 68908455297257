import {LoaderAPIService} from "../../../core/Infrastructure/services/LoaderAPIService";
import {RequestFailed} from "../../../core/Domain/exceptions/RequestFailed";
import {Toast} from "../../../LoaderUI";

export class Parts extends LoaderAPIService {
    private readonly partsUrl: string;

    constructor() {
        super();
        this.partsUrl = this.getBaseUrl() + 'parts'
    }

    /**
     * Creates a new part
     *
     * @param {Part}
     * @return {Part[] | boolean}
     */
    async createPart({name, description, fullLoadCurrent, space, netMaterials, discountRate, totalNetMaterials, installHours}: Part): Promise<Part[] | boolean> {
        const requestOptions: Object = this.buildRequestOptions(
            'POST',
            this.getAuthHeader(),
            {
                'name': name,
                'description': description,
                'fullLoadCurrent': fullLoadCurrent,
                'space': space,
                'netMaterials': netMaterials,
                'discountRate': discountRate,
                'totalNetMaterials': totalNetMaterials,
                'installHours': installHours,
            }
        );

        let response = false;

        try {
            response = await this.request(this.partsUrl, requestOptions, 201, 'data');
        } catch (error) {
            if (error instanceof RequestFailed) {
                Toast.error(error.message);
            }
        }

        return response
    }

    /**
     * Get all Parts
     *
     * @returns {Promise<Part[] | boolean>}
     */
    async fetchParts(name: string | null = null): Promise<Part[] | boolean> {
        const requestOptions: Object = this.buildRequestOptions(
           'GET',
           this.getAuthHeader(),
        );

        let getParams = '';

        if (name !== null) {
            getParams = "/" + name;
        }

       return await this.request(this.partsUrl + getParams, requestOptions, 200,'data');
    }

    /**
     * Get all Part names
     *
     * @returns {Promise<string[] | boolean>}
     */
    async getPartNames(): Promise<string[] | boolean> {
        const requestOptions: Object = this.buildRequestOptions(
            'GET',
            this.getAuthHeader()
        );

        return await this.request(this.partsUrl + '/get/names', requestOptions);
    }
}