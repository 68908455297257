import * as React from 'react';
import {useRef} from "react";

type Props = {
    children: React.ReactNode,
    action: Function,
    id?: string,
    className?: string,
    reference?: React.Ref<HTMLDivElement>,
}

export const Button = ({reference, children, action, id, className}: Props): JSX.Element =>  {
    const fakeRef = useRef({} as HTMLDivElement);

    const onClickAction = () => {
        if (action) {
            action();
        }
    }

    return (
        <div ref={reference ?? fakeRef} id={(id ?? '')} className={(className ?? '')} onClick={onClickAction}>
            {children}
        </div>
    );
};