import React, {useEffect, useRef, useState} from 'react';

type Props = {
    menuItems: MenuAction[],
}

export const TableRowMenu = ({menuItems}: Props): JSX.Element => {
    const anchor = useRef({} as HTMLDivElement);
    const menuContainer = useRef({} as HTMLDivElement);

    const [displayMenu, setDisplayMenu] = useState(false);
    const [leftAnchor, setLeftAnchor] = useState(0);

    useEffect(() => {
        if (displayMenu) {
            menuContainer.current.focus();

            if (leftAnchor === 0) {
               let containerDimensions = menuContainer.current.getBoundingClientRect();

                setLeftAnchor(containerDimensions.width + 5);
            }
        }
    }, [displayMenu, leftAnchor])


    const openMenu = () => {
        setDisplayMenu(true);
    }

    const closeMenu = () => {
        setDisplayMenu(false);
    }

    let leftPosition = "-" + leftAnchor + "px";

    return (
        <div className="actions-container">
            <div style={{left: leftPosition}} tabIndex={0} onBlur={closeMenu} className={"menu-container z-50 shadow-md bg-gray-100 absolute grid rounded-md w-40 outline-0	" + (displayMenu ? "" : " hidden")} ref={menuContainer}>
                {menuItems.map((item, index) => {
                    return (
                        <span key={"menu-item" + index}
                              className={"text-left px-4 text-sm hover:bg-gray-200 cursor-pointer py-1"
                                  + (index === 0 ? " rounded-t-md": index === menuItems.length - 1 ? " rounded-b-md" : "")
                                  + (item.isDestructive ? " text-red-500" : "")
                              }
                              onClick={() => {item.function()}}
                        >
                            {item.label}
                        </span>
                    )
                })}

            </div>
            <div ref={anchor} onClick={openMenu} className="table-row-menu-trigger p-2">
                <i className="fa-solid fa-ellipsis-vertical cursor-pointer"></i>
            </div>
        </div>
    )
}