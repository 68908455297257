import {Tabs} from "core/Presentation/components/Tabs";
import {useRef, useState} from "react";
import {KitsTable} from "Kits/Presentation/Components/Kits/KitsTable";
import {PartsTable} from "Kits/Presentation/Components/Parts/PartsTable";
import React from "react";
import {Button, Modal} from "../../../LoaderUI";
import {CreateKitModal} from "../Components/Kits/Modals/CreateKitModal/CreateKitModal";

export const Kits = (): JSX.Element => {
    const tabs = ["Kits", "Parts"];
    const [selectedTab, setSelectedTab] = useState<string>("Kits");
    const [updateKey, setUpdateKey] = useState<number>(0);
    const addKitsRef = useRef({} as HTMLInputElement);

    const switchTab = (tab: string): void => {
        setSelectedTab(tab);
    }

    const reload = ():void => {
        setUpdateKey(updateKey + 1);
    }

    const createKits = () => {
        Modal.open(<CreateKitModal onClose={switchTab}/>,false, 60, 70);

    }

    /*const createParts = () => {
        Modal.open(<KitSearchModal onClose={switchTab} quoteId={parseInt(1)}/>,false, 60);
    }*/

    return (
        <div className="p-8">
            <div className="grid grid-cols-8 gap-4 mb-4">
                <Tabs className="self-end col-span-7 align-middle h-11" tabs={tabs} action={switchTab} selected={selectedTab} />
                <Button action={createKits} reference={addKitsRef}>
                   <span>Create {selectedTab}</span>
                </Button>
            </div>
            <div className="view-table" key={updateKey}>
                {selectedTab === "Kits" ? <KitsTable /> : <PartsTable />}
            </div>
        </div>
    )
}