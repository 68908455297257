import {Button, Input, Modal} from "LoaderUI"
import {useRef} from "react";
import {CustomerService} from "../../Infrastructure/Services/CustomerService";

type Props = {
    onClose?: Function
}

export const CreateCustomer = ({onClose}: Props) => {
    const name = useRef({} as HTMLInputElement);
    const email = useRef({} as HTMLInputElement);
    const telephone = useRef({} as HTMLInputElement);
    const website = useRef({} as HTMLInputElement);

    const createCustomer = () => {
        let customerService = new CustomerService();

        let newCustomer = {
            name: name.current.value,
            email: email.current.value,
            telephone: Number(telephone.current.value),
            website: website.current.value
        } as Customer

        customerService.createCustomer(newCustomer).then(() => {
            if (onClose) {
                onClose()
            }

            Modal.close();
        })
    }

    return (
        <div className="p-8 h-full relative">
             <div id="customer-creation-header">
                 <h1 className="text-xl">Customer Creation Details</h1>
                 <p className="text-base mt-2 text-gray-700">Fill out the base details for your new Customer</p>
             </div>
            <div id="customer-creation-body">
                <Input
                    wrapperClassName="grid text-sm mt-8"
                    labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                    inputClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md"
                    reference={name}
                    label="NAME"
                    type="text"
                    disabled={false}
                    autoFocus={false}
                    placeholder=""
                />
                <Input
                    wrapperClassName="grid text-sm mt-8"
                    labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                    inputClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md"
                    reference={email}
                    label="EMAIL"
                    type="text"
                    disabled={false}
                    autoFocus={false}
                    placeholder=""
                />
                <Input
                    wrapperClassName="grid text-sm mt-8"
                    labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                    inputClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md"
                    reference={telephone}
                    label="TELEPHONE"
                    type="text"
                    disabled={false}
                    autoFocus={false}
                    placeholder=""
                />
                <Input
                    wrapperClassName="grid text-sm mt-8"
                    labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                    inputClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md"
                    reference={website}
                    label="WEBSITE"
                    type="text"
                    disabled={false}
                    autoFocus={false}
                    placeholder=""
                />
            </div>
            <div id="create-customer-footer" className="flex absolute right-5 bottom-5  w-3/6">
                <Button action={() => {Modal.close()}} className="ml-4 p-3 w-32 bg-gray-800 text-center items-center rounded-md text-sm hover:cursor-pointer">
                    <span className="text-gray-200 text-md">Close</span>
                </Button>
                <Button action={createCustomer} className=" ml-4 p-3 w-40 bg-gray-800 text-center items-center rounded-md text-sm hover:cursor-pointer">
                    <span className="text-gray-200 text-md">Create Customer</span>
                </Button>
            </div>
        </div>
    )
}