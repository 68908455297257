import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {QuoteHeader} from "../components/QuoteHeader";
import {QuoteLinesTable} from "../components/QuoteLine/QuoteLinesTable";
import {QuoteTotals} from "../components/QuoteTotals";
import {Quotes} from "../../Infrastructure/services/Quotes";
import {format} from 'date-fns'
import {QuoteLines} from "../../Infrastructure/services/QuoteLine";
import {KitSearchModal} from "../components/KitSearch/KitSearchModal";
import {Button, Modal} from "../../../LoaderUI";


type Params = {
    id: string
}

export const Quote = () => {
    const quoteService = new Quotes();
    const quoteLineService = new QuoteLines();

    const [loaded, setLoaded] = useState(false);
    const [quoteState, setQuoteState] = useState({
        quoteHeaderDetails: {
            customerDetails: {},
            contactDetails: {},
            billingAddress: {}
        },
        quoteTotals: {}
    } as Partial<Quote>);

    const [quoteLines, setQuoteLines] = useState([] as QuoteLine[]);
    const [quoteTotals, setQuoteTotals] = useState({} as QuoteTotalsDetails);

    const [saveQuoteLine, setSaveQuoteLine] = useState(null as QuoteLine | null);

    let params = useParams() as Params;
    let Navigate = useNavigate();

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
            if ((/^\d+$/.test(params.id))) {
                quoteService.fetchById(params.id as unknown as number).then(response => {
                    let responseData = response as Quote;

                    let quoteHeaderDetails = responseData.quoteHeaderDetails as QuoteHeaderDetails;
                    let quoteTotalDetails = responseData.quoteTotals as QuoteTotalsDetails;

                    let dateCreated = (quoteHeaderDetails.dateCreated as DateDetails).date;
                    let dateCreatedFormatted = quoteHeaderDetails.dateCreated !== null ? format(new Date(dateCreated as Date as unknown as string), 'dd/MM/yyyy') : "N/A";

                    let datePaid = quoteHeaderDetails.datePaid != null ? (quoteHeaderDetails.datePaid as DateDetails).date : "Not Paid";
                    let datePaidFormatted = datePaid !== "Not Paid" ? format(new Date(datePaid as Date as unknown as string), 'dd/MM/yyyy') : "Not Paid";

                    let dateSent = quoteHeaderDetails.dateSent != null ? (quoteHeaderDetails.dateSent as DateDetails).date : "Not Sent";
                    let dateSentFormatted = dateSent !== "Not Sent" ? format(new Date(dateSent as Date as unknown as string), 'dd/MM/yyyy') : "Not Sent";

                    let datePrinted = quoteHeaderDetails.datePrinted != null ? (quoteHeaderDetails.datePrinted as DateDetails).date : "Not Printed";
                    let datePrintedFormatted = datePrinted !== "Not Printed" ? format(new Date(datePrinted as Date as unknown as string), 'dd/MM/yyyy') : "Not Printed";

                    setQuoteState({
                        quoteHeaderDetails: {
                            dateCreated: dateCreatedFormatted,
                            datePaid: datePaidFormatted,
                            dateSent: dateSentFormatted,
                            datePrinted: datePrintedFormatted,
                            customerDetails: {
                                name: quoteHeaderDetails.customerDetails.name,
                                email: quoteHeaderDetails.customerDetails.email,
                                website: quoteHeaderDetails.customerDetails.website,
                                telephoneNumber: quoteHeaderDetails.customerDetails.telephoneNumber,
                            },
                            contactDetails: {
                                firstName: quoteHeaderDetails.contactDetails.firstName,
                                lastName: quoteHeaderDetails.contactDetails.lastName,
                                email: quoteHeaderDetails.contactDetails.email,
                                primaryTelephoneNumber: quoteHeaderDetails.contactDetails.primaryTelephoneNumber,
                                secondaryTelephoneNumber: quoteHeaderDetails.contactDetails.secondaryTelephoneNumber,
                            },
                            billingAddress: {
                                lineOne: quoteHeaderDetails.billingAddress?.lineOne ?? "",
                                lineTwo: quoteHeaderDetails.billingAddress?.lineTwo ?? "",
                                lineThree: quoteHeaderDetails.billingAddress?.lineThree ?? "",
                                city: quoteHeaderDetails.billingAddress?.city ?? "",
                                postcode: quoteHeaderDetails.billingAddress?.postcode ?? "",
                            }
                        },
                        quoteTotals: {
                            contractRate: quoteTotalDetails.contractRate,
                            workshopRate: quoteTotalDetails.workshopRate,
                            softwareRate: quoteTotalDetails.softwareRate,
                            contingencyRate: quoteTotalDetails.contingencyRate,
                            materialRate: quoteTotalDetails.materialRate,
                            softwareHours: quoteTotalDetails.softwareHours,
                            contractHours: quoteTotalDetails.contractHours
                        }
                    });

                    fetchQuoteLines();
                });
            } else {
                Navigate("/quotes/overview");
            }
        }

        updateTotals(null);

        if (saveQuoteLine !== null) {
            const timer = setTimeout(() => {
                quoteLineService.updateQuoteLine(saveQuoteLine).then(() => {
                    setSaveQuoteLine(null)
                });
            }, 500);
            return () => {
                clearTimeout(timer);
            }
        }
    }, [loaded, params.id, Navigate, quoteLines, saveQuoteLine]);

    const fetchQuoteLines = () => {
        quoteLineService.fetchQuoteLinesById(parseInt(params.id)).then(response => {
            let responseData = response as [];

            if (response) {
                setQuoteLines(responseData);
            }
        });
    }

    const updateTotals = (totalRates: Partial<QuoteTotalsDetails> | null) => {
        let currentRates = totalRates ?? quoteState.quoteTotals;

        let currentQuoteLines = [...quoteLines];

        let totalNetMaterials = 0;

        let totalHours = 0;
        let totalMinutes = 0;
        let totalSeconds = 0;
        let totalSpace = 0;
        let totalCurrent = 0;

        for (let i = 0; i < currentQuoteLines.length; i++) {
            if (currentQuoteLines[i].parentId !== null) {
                continue;
            }

            totalNetMaterials = totalNetMaterials + (currentQuoteLines[i].listPrice ?? 0);
            totalHours = totalHours + (currentQuoteLines[i].installHours.hours ?? 0) * currentQuoteLines[i].quantity;
            totalMinutes = totalMinutes + (currentQuoteLines[i].installHours.minutes ?? 0) * currentQuoteLines[i].quantity;
            totalSeconds = totalSeconds + (currentQuoteLines[i].installHours.seconds ?? 0) * currentQuoteLines[i].quantity;
            totalCurrent = totalCurrent + (currentQuoteLines[i].fullLoadCurrent ?? 0) * currentQuoteLines[i].quantity;
            totalSpace = totalSpace + (currentQuoteLines[i].space ?? 0) * currentQuoteLines[i].quantity;
        }

        let netMaterialCost = totalNetMaterials + ((currentRates?.materialRate ?? 0) / 100 * (totalNetMaterials));


        let contractMinutes = (currentRates?.contractHours?.minutes ?? 0) / 60;
        let contractHours = (currentRates?.contractHours?.hours ?? 0);

        let softwareMinutes = (currentRates?.softwareHours?.minutes ?? 0) / 60;
        let softwareHours = (currentRates?.softwareHours?.hours ?? 0);

        let softwareTime = softwareHours + softwareMinutes;
        let contractTime = contractHours + contractMinutes;

        let softwareCost = softwareTime * (currentRates?.softwareRate ?? 0);
        let contractCost = contractTime * (currentRates?.contractRate ?? 0);

        let workshopCost = (totalHours + (totalMinutes / 60)) * (currentRates?.workshopRate ?? 0);

        let contingencyCost = (workshopCost + softwareCost + contractCost + netMaterialCost) * ((currentRates?.contingencyRate ?? 0) / 100);

        let totals = {
            materialCost: netMaterialCost,
            workshopCost: workshopCost,
            workshopRate: currentRates?.workshopRate,
            workshopHours: {
                hours: totalHours,
                minutes: totalMinutes,
                seconds: totalSeconds,
            } as DurationDetails,
            contingencyRate: currentRates?.contingencyRate,
            materialRate: currentRates?.materialRate,
            softwareRate: currentRates?.softwareRate,
            softwareCost: softwareCost,
            softwareHours: currentRates?.softwareHours,
            contractRate: currentRates?.contractRate,
            contractCost: contractCost,
            contractHours: currentRates?.contractHours,
            contingencyCost: contingencyCost,
            totalCurrent: totalCurrent,
            totalSpace: totalSpace
        } as QuoteTotalsDetails


        setQuoteTotals(totals);
    }

    const updateQuoteLine = async (quoteLine: QuoteLine, remove = false, part?: QuoteLine) => {
        let existingObject = quoteLines.find(quoteLinesItem => quoteLinesItem.id === quoteLine.id);
        let index = quoteLines.indexOf(existingObject as QuoteLine);
        let newQuoteLines = [...quoteLines];

        if (part) {
            let parts = (existingObject as QuoteLine).parts as QuoteLine[];

            let partObject = parts.find(partLineItem => partLineItem.id === part.id);
            let partIndex = parts.indexOf(partObject as QuoteLine);

            let newParts = [...parts];

            if (remove) {
                if (newParts.length === 1) {
                    newQuoteLines.splice(index, 1);

                    await quoteLineService.removeQuoteLine(quoteLine);

                } else {
                    newParts.splice(partIndex, 1);
                    await quoteLineService.removeQuoteLine(part);
                }
            } else {
                newParts[partIndex].quantity = quoteLine.quantity;
                newParts[partIndex].space = quoteLine.space;
                newParts[partIndex].fullLoadCurrent = quoteLine.fullLoadCurrent;
                newParts[partIndex].netMaterials = quoteLine.netMaterials;
                newParts[partIndex].discountRate = quoteLine.discountRate;
                newParts[partIndex].listPrice = quoteLine.listPrice;

                (existingObject as QuoteLine).parts = newParts;
            }

            if (newQuoteLines[index]) {
                newQuoteLines[index].parts = newParts;
            }
        } else if (remove) {
            await quoteLineService.removeQuoteLine(quoteLine);

            newQuoteLines.splice(index, 1);
        } else {
            newQuoteLines[index].quantity = quoteLine.quantity;
            newQuoteLines[index].space = quoteLine.space;
            newQuoteLines[index].fullLoadCurrent = quoteLine.fullLoadCurrent;
            newQuoteLines[index].netMaterials = quoteLine.netMaterials;
            newQuoteLines[index].discountRate = quoteLine.discountRate;
            newQuoteLines[index].listPrice = quoteLine.listPrice;

            setSaveQuoteLine(newQuoteLines[index]);
        }

        setQuoteLines(newQuoteLines);
    }

    const addKit = () => {
        Modal.open(<KitSearchModal onClose={fetchQuoteLines} quoteId={parseInt(params.id)}/>,false);
    }

    return (
        <div id="quotes-container" className="h-full w-full bg-gray-200">
            <div className="quote-actions flex">
                <h1 className="px-12 py-8 col-span-12 text-2xl">Quote #{params.id}</h1>
                <Button action={addKit} className="absolute right-10 top-8 p-2 text-gray-200 rounded-md border border-gray-300 bg-gray-800 cursor-pointer">
                    <span>Add Kits & Parts</span>
                </Button>
            </div>
            <div className="quotes-main-body h-5/6 w-full grid grid-cols-12 gap-2 px-8 py-2">
                <div id="quote-details" className="h-4/6 grid gap-5 col-span-9">
                    <QuoteHeader {...quoteState.quoteHeaderDetails as QuoteHeaderDetails}/>
                    <QuoteLinesTable quoteLines={quoteLines} updateQuoteLine={updateQuoteLine} />
                </div>
                <QuoteTotals setQuoteTotals={updateTotals} quoteTotals={quoteTotals as QuoteTotalsDetails} quoteId={parseInt(params.id)}/>
            </div>
        </div>
    );
}