import {useEffect, useRef, useState} from "react";
import {Kits} from "Kits/Infrastructure/services/Kits";
import React from "react";
import {KitRow} from "./KitRow";
import {Button} from "LoaderUI";

export const KitsTable = (): JSX.Element => {
    const [kits, setKits] = useState<Kit[]>([] as Kit[]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const addKitButtons = useRef<HTMLTableRowElement>({} as HTMLTableRowElement);

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
            const newKits = new Kits();

            newKits.fetchKits().then(response  => {
                let responseArray = response as Array<Kit>;
                let newKits = [] as Kit[];

                if (response) {
                    newKits = responseArray.sort(function(a, b) {
                        let firstItem = new Date(a.dateCreated as string);
                        let lastItem = new Date(b.dateCreated as string);

                        return firstItem > lastItem ? 1 : -1;
                    });
                }

                setKits(newKits);
            });
        }
    }, [loaded]);

    const addKit = () => {
        let activeNewKits = document.querySelectorAll(".new-kit");

        if (activeNewKits.length === 0) {
            let newKit: Kit = {
                name: '',
                description: '',
                fullLoadCurrentTotal: 0,
                space: 0,
                totalNetMaterials: 0,
                installHours: {
                   hours: 0,
                   minutes: 0,
                   seconds: 0
                } as DurationDetails,
                isNew: true,
            }

            setKits([newKit, ...kits]);
        } else {
            console.log('Finish your current kit');
        }
    };

    const updateTableItem = (item: Kit, index: number) => {
        let currentKits = [...kits];

        currentKits[index] = {
            ...currentKits[index],
            name: item.name,
            description: item.description,
            fullLoadCurrentTotal: item.fullLoadCurrentTotal,
            space: item.space,
            totalNetMaterials: item.totalNetMaterials,
            installHours: item.installHours,
            isNew: item.isNew,
        };

        setKits(currentKits);
    }

    return (
        <table className="border-collapse table-auto w-full text-sm bg-gray-100 shadow-sm rounded-md h-5/6">
            <thead className="bg-gray-200 rounded-md relative">
               {/* <tr className="absolute bottom-0 left-0 right-0" ref={addKitButtons}>
                    <td className="absolute -top-5 left-1/2 transform -translate-x-1/2  bg-gray-600 rounded-full w-10 h-10 grid place-items-center">
                        <Button className="relative w-full h-full" action={addKit}>
                            <span className="absolute text-3xl text-gray-300 inline-block align-top left-1/2 transform -translate-x-1/2">+</span>
                        </Button>
                    </td>
                </tr>*/}
                <tr className="rounded-md">
                    <th className="rounded-tl-md font-medium p-3 text-gray-500 text-left text-xs uppercase align-middle">Name</th>
                    <th className="font-medium p-3 text-gray-500 text-left text-xs uppercase align-middle">Description</th>
                    <th className="font-medium p-3 text-gray-500 text-center text-xs uppercase align-middle">FLC</th>
                    <th className="font-medium p-3 text-gray-500 text-center text-xs uppercase align-middle">Space</th>
                    <th className="font-medium p-3 text-gray-500 text-center text-xs uppercase align-middle">Net Mat</th>
                    <th className="rounded-tr-md font-medium p-3 text-gray-500 text-left text-xs uppercase align-middle">Total Hours</th>
                </tr>
            </thead>
            <tbody id="kit-table-body">
                {kits.map((kit: Kit, index: number) => {
                        return (
                            <KitRow
                                key={'kit' + index}
                                name={kit.name}
                                description={kit.description}
                                fullLoadCurrent={kit.fullLoadCurrentTotal}
                                space={kit.space}
                                netMaterials={kit.totalNetMaterials}
                                totalHours={kit.installHours as DurationDetails}
                                isNew={kit.isNew as boolean}
                                index={index as number}
                                updateTableItem={updateTableItem as Function}
                            />
                        )
                    }
                )}
            </tbody>
        </table>
    );
}