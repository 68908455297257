import {Authentication} from "core/Infrastructure/services/Authentication";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Loading} from "../../views/Loading";

type Props = {
    content: JSX.Element
}

export const ProtectedRoute = ({content}: Props): JSX.Element => {
    const [loaded, setLoaded] = useState(false);
    const Navigate = useNavigate();

    useEffect(() => {
        if (!loaded) {
            const auth: Authentication = new Authentication();
            
            auth.checkAccess().then(response => {
                    if (!response) {
                        Navigate('/login');
                    } else {
                       setLoaded(true);
                    }
                }
            );
        }
    });

    if (!loaded) {
        return <Loading />
    } else {
        return content;
    }
}