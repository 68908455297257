import {LoaderAPIService} from "./LoaderAPIService";
import {Toast} from "../../../LoaderUI";
import {RequestFailed} from "../../Domain/exceptions/RequestFailed";

export class Authentication extends LoaderAPIService {
    /**
     * Attempt to authenticate user
     *
     * @param email
     * @param password
     * @return {Promise<Response | boolean>}
     */
    login = async (email: string, password: string): Promise<Response | boolean> => {
        const requestOptions: Object = this.buildRequestOptions(
            'POST',
            {'Content-Type': 'application/json'},
            {'email': email, 'password': password}
        );

        let response = false;

        try {
            let token = await this.request(
                this.getBaseUrl() + 'auth/login_check',
                requestOptions,
                200,
                'token'
            );

            this.setAuthToken(token);

            response = true;
        } catch (error: any) {
            if (error instanceof RequestFailed) {
                Toast.error(error.message);
            }
        }

        return response;
    };

    /**
     * Logout the current user
     *
     * @returns {Promise<void>}
     */
    async logout(): Promise<void> {
       this.removeAuthToken()
    }

    /**
     * Check if the current user is authenticated
     *
     * @returns {Promise<boolean>}
     */
    checkAccess = async (): Promise<boolean> => {
        const requestOptions: Object = {
            method: 'GET',
            headers: this.getAuthHeader()
        };

        return await this.request(
            this.getBaseUrl() + 'auth/check',
            requestOptions,
            200,
            'message',
            'Authenticated'
        );
    };

    /**
     * Remove Auth Token from local storage
     */
    private removeAuthToken = (): void => {
        localStorage.removeItem('token');
    };

    /**
     * Save Auth token in local storage
     *
     * @param token
     */
    private setAuthToken = (token: string): void => {
        localStorage.setItem('token', token);
    };
}