import React, {useRef} from "react";
import {Input} from "../../../../LoaderUI";
import {Kits} from "../../../Infrastructure/services/Kits";
import {Parts} from "../../../Infrastructure/services/Parts";

type Props = {
    name: string,
    description: string,
    fullLoadCurrent: number,
    space: number,
    netMaterials: number,
    totalHours: DurationDetails,
    isNew: boolean,
    index: number,
    updateTableItem: Function,
}

export const PartRow = ({name, description, fullLoadCurrent, space, netMaterials, totalHours, isNew, index, updateTableItem}: Props): JSX.Element => {
    const partName = useRef<HTMLInputElement>({} as HTMLInputElement);
    const partDescription = useRef<HTMLInputElement>({} as HTMLInputElement);

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && isNew) {
            let partsService = new Parts();
            let newName = partName.current.value;
            let newDescription = partDescription.current.value;

            let newPart: Part = {
                name: newName,
                description: newDescription,
                fullLoadCurrent: fullLoadCurrent,
                space: space,
                netMaterials: netMaterials,
                installHours: totalHours,
                isNew: false,
            }

            partsService.createPart(newPart).then(response => {
                if (response) {
                    updateTableItem(newPart, index);
                }
            });
        }
    }

    return (
        <tr className={"text-base text-gray-800 border-b border-gray-300 " + (isNew ? "new-part shadow-lg shadow-gray-300" : "")}>
            <td className={isNew ? "p-0" : "p-3"}>
                {(isNew ? <Input onKeyPress={handleKeyPress} autoFocus={true} placeholder='Enter a name' reference={partName} type="text" inputClassName="is-new h-full bg-gray-100 p-3 placeholder:text-sm w-full" wrapperClassName="h-full w-full bg-gray-100 p-1"/> : name)}
            </td>
            <td className={isNew ? "p-0" : "p-3"}>
                {(isNew ? <Input onKeyPress={handleKeyPress} placeholder="Enter a description" reference={partDescription} type="text" inputClassName="is-new h-full w-full bg-gray-100 p-3 placeholder:text-sm" wrapperClassName="h-full w-full bg-gray-100 p-1"/> : description)}
            </td>
            <td className="p-3 text-center">
                {fullLoadCurrent}
            </td>
            <td className="p-3 text-center">
                {space}
            </td>
            <td className="p-3 text-center">
                {netMaterials}
            </td>
            <td className="p-3 text-center">
            </td>
            <td className="p-3 text-center">
            </td>
            <td className="p-3 text-center">
            </td>
            <td className="p-3">
                ""
            </td>
        </tr>
    );
}