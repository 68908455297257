import {LoaderAPIService} from "../../../core/Infrastructure/services/LoaderAPIService";
import {RequestFailed} from "../../../core/Domain/exceptions/RequestFailed";
import {Toast} from "../../../LoaderUI";

export class Quotes extends LoaderAPIService {
    private readonly quotesUrl: string;
    
    constructor() {
        super();
        this.quotesUrl = this.getBaseUrl() + 'quotes'
    }

    /**
     * Creates a new Kit
     *
     * @returns {Promise<Object | boolean>}
     */
    async create(subject: string, description: string, contingency: number, software: number, workshop: number, contract: number, material: number, contact: number, softwareHours: DurationDetails, contractHours: DurationDetails): Promise<Object | false> {
        const requestOptions: Object = this.buildRequestOptions(
            'POST',
            this.getAuthHeader(),
            {
                subject: subject,
                description: description,
                contingencyRate: contingency,
                softwareRate: software,
                workshopRate: workshop,
                contractRate: contract,
                materialRate: material,
                contact: contact,
                softwareHours: softwareHours,
                contractHours: contractHours
            }
        );

        let response = false;

        try {
            response = await this.request(this.quotesUrl, requestOptions, 201, 'data');
        } catch (error) {
            if (error instanceof RequestFailed) {
                Toast.error(error.message);
            }
        }

        return response;
    }

    async updateTotals(quoteId: number, quote: Partial<QuoteTotalsDetails>): Promise<Object | boolean> {
        const requestOptions: Object = this.buildRequestOptions(
            'PUT',
            this.getAuthHeader(),
            {
                quote: quote
            }
        );

        return await this.request(this.quotesUrl + "/totals/" + quoteId, requestOptions, 200, 'message');
    }


    /**
     * Fetch all quotes
     *
     * @returns {Promise<Quote[] | boolean>}
     */
    async fetch(name: string | null = null): Promise<Quote[] | boolean> {
        const requestOptions: Object = this.buildRequestOptions(
            'GET',
            this.getAuthHeader(),
        );


        let getParams = '';

        if (name !== null) {
            getParams = "/" + name;
        }

        return await this.request(this.quotesUrl + getParams, requestOptions, 200,'data');
    }

    async fetchById(id: number): Promise<Quote | boolean> {
        const requestOptions: Object = this.buildRequestOptions(
            'GET',
            this.getAuthHeader()
        );

        return await this.request(this.quotesUrl + "/" + id, requestOptions, 200, 'data');
    }
}