import { Input } from "LoaderUI/Input"
import {useRef} from "react";
import {Button, Modal, TextArea} from "../../../../../../LoaderUI";
import {Kits} from "../../../../../Infrastructure/services/Kits";

type Props = {
    className: string
    kitTotals: Partial<Kit>
    kitParts: Part[]
}

export const KitDetails = ({className, kitTotals, kitParts}: Props) => {
    const kitNameRef = useRef({} as HTMLInputElement);
    const spaceRef = useRef({} as HTMLInputElement);
    const netMaterialsRef = useRef({} as HTMLInputElement);
    const fullLoadCurrentRef = useRef({} as HTMLInputElement);
    const totalHoursRef = useRef({} as HTMLInputElement);
    const descriptionRef = useRef({} as HTMLTextAreaElement);

    const addKitButton = useRef(({} as HTMLInputElement));

    let totalInstallHours = (kitTotals.installHours as DurationDetails).hours
        + ':'
        + (kitTotals.installHours as DurationDetails).minutes
        + ':'
        + (kitTotals.installHours as DurationDetails).seconds;

    const createKit = () => {
        let kitService = new Kits();

        let parts = kitParts.map((part) => {
           return {
               id: part.id,
               quantity: part.quantity ?? 1
           } as KitPart;
        });

        let newKit: Partial<Kit> = {
            name: kitNameRef.current.value,
            description: descriptionRef.current.value,
            partsIncluded: parts,
        };

        kitService.createKit(newKit).then((response) => {
            if (response) {
                Modal.close();
            }
        });
    }

    return (
        <div id="kit-creation-form" className={className}>
                 <div id="kit-creation-header">
                         <h1 className="text-xl">Kit Creation Details</h1>
                         <p className="text-base mt-2 text-gray-700">Fill out the base details for your new Kit, then add your Parts</p>
                 </div>
                 <div id="kit-creation-body" className="pb-6">
                     <Input
                         wrapperClassName="grid text-sm w-full mt-8"
                         labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                         inputClassName="p-1.5 text-base disabled w-full bg-gray-100 border border-gray-200 rounded-md"
                         reference={kitNameRef}
                         label="KIT NAME"
                         type="text"
                         disabled={false}
                         autoFocus={false}
                         placeholder=""
                     />
                     <TextArea
                         wrapperClassName="grid text-sm w-full mt-8"
                         areaClassName="p-1.5 text-base disabled w-full border border-gray-200 bg-gray-100 rounded-md resize-none h-28"
                         labelClassName="pl-1 pb-1 text-xs text-gray-600 font-medium w-full"
                         label="DESCRIPTION" reference={descriptionRef} />
                     <div className="grid gap-6 grid-cols-4">
                         <Input
                             wrapperClassName="grid text-sm w-full mt-8"
                             labelClassName="pl-1 pb-1 text-xs text-center text-gray-600 font-medium w-full"
                             inputClassName="p-1.5 text-base text-center disabled w-full bg-gray-100 rounded-md"
                             reference={fullLoadCurrentRef}
                             label="FULL LOAD CURRENT"
                             type="text"
                             disabled={true}
                             autoFocus={false}
                             value={String(kitTotals.fullLoadCurrentTotal)}
                             placeholder=""
                         />
                         <Input
                             wrapperClassName="grid text-sm w-full mt-8"
                             labelClassName="pl-1 pb-1 text-xs text-center text-gray-600 font-medium w-full"
                             inputClassName="p-1.5 text-base text-center disabled w-full bg-gray-100 rounded-md"
                             reference={totalHoursRef}
                             label="TOTAL INSTALL HOURS"
                             type="text"
                             disabled={true}
                             autoFocus={false}
                             value={totalInstallHours}
                             placeholder=""
                         />
                         <Input
                             wrapperClassName="grid text-sm w-full mt-8"
                             labelClassName="pl-1 pb-1 text-xs text-center text-gray-600 font-medium w-full"
                             inputClassName="p-1.5 text-base text-center disabled w-full bg-gray-100 rounded-md"
                             reference={spaceRef}
                             label="SPACE"
                             type="text"
                             disabled={true}
                             autoFocus={false}
                             value={String(kitTotals.space)}
                             placeholder=""
                         />
                         <Input
                             wrapperClassName="grid text-sm w-full mt-8"
                             labelClassName="pl-1 pb-1 text-xs text-center text-gray-600 font-medium w-full"
                             inputClassName="p-1.5 text-base text-center disabled w-full bg-gray-100 rounded-md"
                             reference={netMaterialsRef}
                             label="TOTAL NET MATERIALS (£)"
                             type="text"
                             disabled={true}
                             autoFocus={false}
                             value={String(kitTotals.totalNetMaterials)}
                             placeholder=""
                         />
                     </div>
                     <div className="grid gap-6 grid-cols-2">

                     </div>
                     <Button className="cursor-pointer absolute bottom-0 right-0 ml-auto mb-3 p-2 mt-auto mr-4 w-36 flex justify-center items-center border border-gray-300 rounded-md text-gray-200 bg-gray-800" action={() => {createKit()}} reference={addKitButton}>
                             <span className="text-center">Create Kit</span>
                     </Button>
                 </div>
             </div>
    )
}