import React from "react";
import {QuotesOverviewTable} from "Quotes/Presentation/components/OverviewTable";
import {Button, Modal} from "../../../LoaderUI";
import {SlideOutModal} from "../../../LoaderUI/SlideOutModal";
import {CreateKitModal} from "../../../Kits/Presentation/Components/Kits/Modals/CreateKitModal/CreateKitModal";
import {AddQuote} from "../components/AddQuote/AddQuote";

export const QuotesOverview = () => {
    // const tabs = ["Overview"]sss
    // const [selectedTab] = useState("Overview");

    return (
        <div id="quotes-overview-container" className="relative h-full w-full bg-gray-200 p-12">
            <Button action={() => {SlideOutModal.open(<AddQuote />)}} className="absolute right-10 top-8 p-2 text-gray-200 rounded-md border border-gray-300 bg-gray-800 cursor-pointer">
                <span>New Quote</span>
            </Button>
            <div className="mt-10">
                <QuotesOverviewTable />
            </div>
        </div>
    );
}