import React, {useEffect, useState} from "react";

type Props = {
    action: Function,
    searchBoxClass: string,
    wrapperClass: string,
    reference: React.Ref<HTMLInputElement>,
    autoSearch: boolean,
    placeholder?: string,
}

export const SearchBox = ({action, searchBoxClass, wrapperClass, reference, autoSearch, placeholder}: Props) => {
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (autoSearch) {
            const timer = setTimeout(() => {
                if (inputValue.length > 3 && (action !== null && action !== undefined) ) {
                    action(inputValue);
                }
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [action, autoSearch, inputValue])

    const textChange = (event: React.ChangeEvent) => {
        let value = (event.target as HTMLInputElement).value;

        setInputValue(value);
    }

    return (
        <div className={"flex " + wrapperClass}>
            <div className="h-full ml-2 mr-3">
                <i className="fa-solid fa-search text-gray-500 text-sm"></i>
            </div>
            <input ref={reference} type="text" placeholder={placeholder ?? ''} className={searchBoxClass} onChange={textChange} onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    action((event.target as HTMLInputElement).value);
                } else {
                    return false;
                }
            }}/>
        </div>
    )
}