import React from "react";
import { useRef, useState } from "react";
import {Button, Input} from "../../../../LoaderUI";
import {QuoteLinePart} from "./QuoteLinePart";
import {DurationPicker} from "../../../../LoaderUI/DurationPicker";

type Props = {
    quoteLine: QuoteLine,
    updateQuoteLine: Function
    index: number,
}

export const QuoteLineRow = ({quoteLine, updateQuoteLine, index}: Props) => {
    const rowDropDownRef = useRef({} as HTMLElement);

    const nameRef = useRef({} as HTMLInputElement);
    const descriptionRef = useRef({} as HTMLInputElement);
    const spaceRef = useRef({} as HTMLInputElement);
    const fullLoadCurrentRef = useRef({} as HTMLInputElement);
    const netMaterialsRef = useRef({} as HTMLInputElement);
    const quantityRef = useRef({} as HTMLInputElement);
    const discountRef = useRef({} as HTMLInputElement);
    const listPriceRef = useRef({} as HTMLInputElement);

    const [lineHours, setLineHours] = useState({
        hours: quoteLine.installHours.hours,
        minutes: quoteLine.installHours.minutes,
        seconds: (quoteLine.installHours.seconds ?? 0)
    });

    const [showParts, setShowParts] = useState(false);
    const partTable = {
         width: "97%",
         marginLeft: "3%",
    };

    const onShowParts = (event: React.MouseEvent) => {
        if (quoteLine.type !== "kit" || (quoteLine.parts as QuoteLine[]).length <= 0) {
            return
        }

        let icon = (event.currentTarget as HTMLElement).children[0].firstChild as HTMLElement;

        let show;

        if (showParts) {
            icon.style.transform = 'rotate(0deg)';
            show = false;
        } else {
            icon.style.transform = 'rotate(90deg)';
            show = true;
        }

        setShowParts(show);
    }

    const onChangeQuoteLine = (timeValues?: DurationDetails) => {
        let spaceValue = spaceRef.current.value;
        let fullLoadCurrentValue = fullLoadCurrentRef.current.value;
        let netMaterialsValue = netMaterialsRef.current.value;
        let quantityValue = quantityRef.current.value;
        let discountValue = discountRef.current.value;
        let nameValue = nameRef.current.value;

        quoteLine.name = nameValue;
        quoteLine.space = parseFloat(spaceValue);
        quoteLine.fullLoadCurrent = parseFloat(fullLoadCurrentValue);
        quoteLine.netMaterials = parseFloat(netMaterialsValue);
        quoteLine.quantity = parseInt(quantityValue);
        let listPrice = (parseFloat(netMaterialsValue) - (parseFloat(discountValue) / 100 * parseFloat(netMaterialsValue))) * parseInt(quantityValue);
        quoteLine.listPrice = parseFloat(listPrice.toFixed(2));
        quoteLine.discountRate = parseFloat(discountValue);

        listPriceRef.current.value = String(listPrice.toFixed(2));

        if (timeValues && timeValues.hasOwnProperty('hours')) {
            let hours = timeValues.hours;
            let minutes = timeValues.minutes;
            let seconds = timeValues.seconds ?? 0

            setLineHours({
                hours: hours,
                minutes: minutes,
                seconds: seconds,
            });

            quoteLine.installHours.hours = hours;
            quoteLine.installHours.minutes = minutes;
            quoteLine.installHours.seconds = seconds;
        }

        updateQuoteLine(quoteLine);
    }

    const updateKit = () => {
        let kitSpace = 0;
        let kitFullLoadCurrent = 0;
        let kitNetmaterials = 0;
        let kitHours = 0;
        let kitMinutes = 0;
        let kitSeconds = 0;

        let parts = quoteLine.parts ?? [];

        for (let i = 0; i < (parts).length; i++) {
            kitSpace += (parts[i].space ?? 0) * parts[i].quantity;
            kitFullLoadCurrent += (parts[i].fullLoadCurrent ?? 0) * parts[i].quantity;
            kitNetmaterials += parts[i].listPrice ?? 0;

            kitHours += (parts[i].installHours.hours ?? 0) * parts[i].quantity;
            kitMinutes += (parts[i].installHours.minutes ?? 0) * parts[i].quantity;
            kitSeconds += (parts[i].installHours.seconds ?? 0) * parts[i].quantity;
        }

        if ((kitSeconds ?? 0) >= 60) {
           let incrementMinutes = ((kitSeconds ?? 0) / 60).toFixed(0);

           kitMinutes = kitMinutes + Number(incrementMinutes);
           kitMinutes = kitMinutes + Number(incrementMinutes);

           kitSeconds = ((kitSeconds ?? 0) % 60);
        }

        if (kitMinutes >= 60) {
           let incrementHours = (kitMinutes / 60).toFixed(0);

           kitHours = kitHours + Number(incrementHours);

           kitMinutes = (kitMinutes % 60);
        }

        spaceRef.current.value = String(kitSpace);
        fullLoadCurrentRef.current.value = String(kitFullLoadCurrent);

        netMaterialsRef.current.value = String(kitNetmaterials.toFixed(2));

        setLineHours({
            hours: kitHours,
            minutes: kitMinutes,
            seconds: kitSeconds ?? 0,
        })

        onChangeQuoteLine({hours: kitHours,minutes: kitMinutes, seconds: kitSeconds} as DurationDetails);
    }

    const allowRowDropdown = quoteLine.type === "kit" && (quoteLine.parts as QuoteLine[]).length > 0 ? <td onClick={onShowParts} className="absolute left-5 top-4">
            <i ref={rowDropDownRef}  className="delay-75 transition duration-300 ease-in-out fa-solid fa-caret-right text-base text-gray-400"></i>
        </td> : <td className="absolute left-5 top-4"></td>;

    let totalHours = lineHours.hours * quoteLine.quantity;
    let totalMinutes = lineHours.minutes * quoteLine.quantity;
    let totalSeconds = lineHours.seconds * quoteLine.quantity;

    if (totalSeconds >= 60) {
        let incrementMinutes = (totalSeconds / 60).toFixed(0);

        totalMinutes = totalMinutes + parseInt(incrementMinutes);
        totalSeconds = totalSeconds % 60;
    }

    if (totalMinutes >= 60) {
        let incrementHours = (totalMinutes / 60).toFixed(0);

        totalHours = totalHours + parseInt(incrementHours);
        totalMinutes = totalMinutes % 60;
    }

    return (
            <tr className="w-11/12 ml-auto mr-auto block" key={"quoteLineRow" + index}>
                <td id={"quoteLineGroup" + index} className="block">
                    <table className="block bg-gray-200 h-full relative table-fixed border-spacing-4 border-collapse text-sm bg-gray-100 w-full">
                        <tbody className="bg-gray-200 relative block">
                            <tr className="w-full bg-gray-100 mb-1 rounded-md table border-spacing-3 relative p-4 hover:bg-gray-200 text-sm text-gray-800 shadow-md hover:cursor-pointer">
                              {quoteLine.type === "kit" && (quoteLine.parts as QuoteLine[]).length > 0 ?
                                   <td onClick={onShowParts} className="absolute -left-10 h-full">
                                       <div className="flex items-center justify-center h-full w-8 bg-gray-600 rounded-md">
                                          <i ref={rowDropDownRef}  className="delay-75 text-base transition duration-300 ease-in-out fa-solid fa-caret-right text-base text-gray-200"></i>
                                       </div>
                                   </td> : ""
                              }
                              <td className="w-2/12 py-5 inline-block font-medium pl-6 text-gray-500 text-left text-xs uppercase align-middle">
                                  <Input onChange={onChangeQuoteLine} disabled={false} inputClassName="w-full h-full bg-inherit outline-none text-left overflow-hidden text-ellipsis whitespace-nowrap" reference={nameRef} type="text" defaultValue={String(quoteLine.name)} />
                              </td>
                              <td className="w-1/12 inline-block font-medium text-gray-500 text-center text-xs uppercase align-middle">
                                  <Input onChange={onChangeQuoteLine} disabled={false} inputClassName="w-full h-full bg-inherit outline-none py-3 text-center" reference={quantityRef} type="number" defaultValue={String(quoteLine.quantity)} />
                              </td>
                              <td className={"w-1/12 inline-block overflow-hidden text-ellipsis whitespace-nowrap font-medium text-gray-500 text-center text-xs uppercase align-middle " + (quoteLine.type !== 'kit' && quoteLine.parentId !== null ? " " : " ")}>
                                  {quoteLine.type === "kit" ?
                                      (lineHours.hours < 10 ? ("0" + lineHours.hours) : lineHours.hours) + ":" + (lineHours.minutes < 10 ? ("0" + lineHours.minutes) : lineHours.minutes)
                                      : <DurationPicker onChange={onChangeQuoteLine} defaultValue={quoteLine.installHours} showTimeLabels={false} inputLabelClassName="text-xs mb-2 text-center w-full block" inputWrapClassName="relative" timeWrapClassName="gap-2" inputClassName=" w-full bg-inherit text-center outline-none w-full py-2 rounded-md appearance-none" includeSeconds={false} />
                                  }
                              </td>
                              <td className="w-1/12 inline-block font-medium text-gray-500 text-center text-xs uppercase align-middle">
                                  <Input onChange={onChangeQuoteLine} disabled={quoteLine.parentId === null && quoteLine.type === 'kit'} inputClassName="w-full h-full bg-inherit outline-none py-3 text-center" reference={spaceRef} type="number" defaultValue={String(quoteLine.space)} />
                              </td>
                              <td className="w-1/12 inline-block font-medium text-gray-500 text-center text-xs uppercase align-middle">
                                  <Input onChange={onChangeQuoteLine} disabled={quoteLine.parentId === null && quoteLine.type === 'kit'} inputClassName="w-full h-full bg-inherit outline-none py-3 text-center" reference={fullLoadCurrentRef} type="number" defaultValue={String(quoteLine.fullLoadCurrent)} />
                              </td>
                              <td className="w-1/12 inline-block font-medium text-gray-500 text-center text-xs uppercase align-middle">
                                  <Input onChange={onChangeQuoteLine} disabled={quoteLine.parentId === null && quoteLine.type === 'kit'} inputClassName="w-full h-full bg-inherit outline-none py-3 text-center" reference={netMaterialsRef} type="currency" defaultValue={String(quoteLine.netMaterials)} />
                              </td>

                              <td className="w-1/12 inline-block font-medium text-gray-500 text-center text-xs uppercase align-middle">
                                  <Input onChange={onChangeQuoteLine} disabled={false} inputClassName="w-full h-full bg-inherit outline-none py-3 text-center" reference={discountRef} type="number" defaultValue={String(quoteLine.discountRate)} />
                              </td>
                              <td className={"w-1/12 inline-block overflow-hidden text-ellipsis whitespace-nowrap font-medium text-gray-500 text-center text-xs uppercase align-middle "}>
                                  {(quoteLine.space ?? 0) * quoteLine.quantity}
                              </td>
                              <td className={"w-1/12 inline-block overflow-hidden text-ellipsis whitespace-nowrap font-medium text-gray-500 text-center text-xs uppercase align-middle "}>
                                  {(quoteLine.fullLoadCurrent ?? 0) * quoteLine.quantity}
                              </td>
                              <td className={"w-1/12 inline-block overflow-hidden text-ellipsis whitespace-nowrap font-medium text-gray-500 text-center text-xs uppercase align-middle "}>
                                  {(totalHours < 10 ? ("0" + totalHours) : totalHours) + ":" + (totalMinutes < 10 ? ("0" + totalMinutes) : totalMinutes)}
                              </td>
                              <td className="w-1/12 inline-block font-medium text-gray-500 text-center text-xs uppercase align-middle">
                                  <Input disabled={true} inputClassName="w-full h-full bg-inherit outline-none py-3 text-center" reference={listPriceRef} type="currency" defaultValue={String(quoteLine.listPrice)} />
                              </td>
                              <td onClick={() => {updateQuoteLine(quoteLine, true)}} className="absolute -right-10 h-full top-0">
                                  <div className="flex items-center justify-center h-full w-8 bg-gray-600 rounded-md">
                                    <i  className="text-base fa-solid fa-xmark text-gray-200"></i>
                                  </div>
                              </td>
                            </tr>
                        </tbody>
                    </table>
                {showParts && allowRowDropdown && (quoteLine.parts as QuoteLine[]).length > 0 ?
                   <table className="block ml-2 bg-gray-200 h-full table-fixed border-spacing-4 border-collapse text-sm bg-gray-100 w-full">
                       <thead className="block relative bg-gray-200 pr-2" style={{width: "96%"}}>
                        <tr className="block w-full">
                            <th className="w-2/12 inline-block font-medium py-4 pl-4 text-gray-500 text-left text-xs uppercase">Name</th>
                            <th className="w-1/12 inline-block font-medium py-4 text-xs text-gray-500 text-center text-xs uppercase">Qty</th>
                            <th className="w-1/12 inline-block font-medium py-4 pl-4 text-gray-500 text-center text-xs uppercase">Hours</th>
                            <th className="w-1/12 inline-block font-medium py-4 text-gray-500 text-center text-xs uppercase">Space</th>
                            <th className="w-1/12 inline-block font-medium py-4 text-gray-500 text-center text-xs uppercase">FLC</th>
                            <th className="w-1/12 inline-block font-medium py-4 text-gray-500 text-center text-xs uppercase">Net Mat</th>
                            <th className="w-1/12 inline-block font-medium py-4 text-xs text-gray-500 text-center text-xs uppercase">Discount</th>
                            <th className="w-1/12 inline-block font-medium py-4 text-xs text-gray-500 text-center text-xs uppercase">T-Space</th>
                            <th className="w-1/12 inline-block font-medium py-4 text-xs text-gray-500 text-center text-xs uppercase">T-FLC</th>
                            <th className="w-1/12 inline-block font-medium py-4 text-xs text-gray-500 text-center text-xs uppercase">T-HOURS</th>
                            <th className="w-1/12 inline-block font-medium py-4 text-xs text-gray-500 text-center text-xs uppercase">T-NET MAT</th>
                        </tr>
                    </thead>
                    <tbody style={{maxHeight: "305px"}} className="bg-gray-200 relative block overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-200 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                        {(quoteLine.parts as QuoteLine[]).map((part, index) => {
                            return (
                                <QuoteLinePart key={"quoteLinePart" + index + "-" + quoteLine.id} updateKit={updateKit} quoteLine={quoteLine} part={part} updateQuoteLine={updateQuoteLine} />
                            )
                        })}
                     </tbody>
                    </table>
                 : ""}
                </td>
            </tr>
    );
}