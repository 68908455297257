import React, {useEffect, useRef, useState} from "react";
import {Input} from "../Input";


type Props = {
   includeSeconds: boolean
   wrapperClass?: string,
   inputWrapClassName?: string
   inputClassName?: string
   labelClassName?: string
   inputLabelClassName?: string,
   timeWrapClassName?: string
   label?: string,
   onChange?: Function
   showTimeLabels?: boolean
   defaultValue?: DurationDetails,
}

export const DurationPicker = ({defaultValue, includeSeconds, wrapperClass, label, labelClassName, inputWrapClassName, inputLabelClassName, inputClassName, timeWrapClassName, onChange, showTimeLabels = true}: Props) => {
    const hoursRef = useRef({} as HTMLInputElement);
    const minutesRef = useRef({} as HTMLInputElement);
    const secondsRef = useRef({} as HTMLInputElement);

    const [timeValues, setTimeValues] = useState(
        defaultValue ?? {
            hours: 0,
            minutes: 0,
            seconds: 0
    } as DurationDetails);

    useEffect(() => {
        setTimeValues(defaultValue ?? {hours: 0, minutes: 0, seconds: 0} as DurationDetails);
    }, [defaultValue]);

    const updateTime = (event: React.KeyboardEvent) => {
        let currentHours = timeValues.hours;
        let currentMinutes = timeValues.minutes;
        let currentSeconds = timeValues.seconds;

        let newHours = null;
        let newMinutes = null;
        let newSeconds = null;

        let eventValue = (event.target as HTMLInputElement).value;

        let inputValue = eventValue !== "" ? eventValue : "0";

        if ((event.target as HTMLInputElement).classList.contains("hoursInput")) {
            newHours = parseInt(inputValue);
        } else if ((event.target as HTMLInputElement).classList.contains("minutesInput")) {
            newMinutes = parseInt(inputValue);

            if (newMinutes >= 60) {
                currentHours = currentHours + Number((newMinutes / 60).toFixed(0));

                newMinutes = null;
                currentMinutes = (newMinutes ?? 0) % 60;

                minutesRef.current.value = String('0');
                hoursRef.current.value = String(currentHours);
            }
        } else if ((event.target as HTMLInputElement).classList.contains("secondsInput")) {
            newSeconds = parseInt(inputValue);

            if (newSeconds >= 60) {
                currentMinutes = currentMinutes + Number((newSeconds / 60).toFixed(0));
                newSeconds = null;

                currentSeconds = (newSeconds ?? 0) % 60;

                secondsRef.current.value = String('0');
                minutesRef.current.value = String(currentMinutes);

                if (currentMinutes >= 60) {
                    currentHours = currentHours + Number((currentMinutes / 60).toFixed(0));
                    currentMinutes = 0;

                    minutesRef.current.value = String('0');
                    hoursRef.current.value = String(currentHours);
                }
            }
        }

        let updatedTimeValues = {
            hours: newHours ?? currentHours,
            minutes: newMinutes ?? currentMinutes,
            seconds: newSeconds ?? currentSeconds
        }

        setTimeValues(updatedTimeValues);

        if (onChange) {
            onChange(updatedTimeValues);
        }
    }

    let minutesLabel = {};
    let hoursLabel = {};

    if (showTimeLabels) {
        minutesLabel = {
            label: 'Minutes'
        }

        hoursLabel = {
            label: 'Hours'
        }
    }

    return (
         <div className={wrapperClass ?? ""}>
             {label ? (
                 <label className={labelClassName ?? ""}>{label}</label>
             ) : ""}
             <div className={"grid " + (includeSeconds ? "grid-cols-3 " : "grid-cols-2 ") + (timeWrapClassName ?? "")}>
                 <Input
                     defaultValue={timeValues.hours ?? 0}
                     onChange={updateTime}
                     wrapperClassName={inputWrapClassName ?? ""}
                     labelClassName={inputLabelClassName ?? ""}
                     inputClassName={(inputClassName ?? "") + " hoursInput"}
                     reference={hoursRef}
                     {...hoursLabel}
                     type="number"
                     disabled={false}
                     autoFocus={false}
                     placeholder="0"
                     allowNegatives={false}
                 />
                 <Input
                     defaultValue={timeValues.minutes ?? 0}
                     onChange={updateTime}
                     wrapperClassName={inputWrapClassName ?? ""}
                     labelClassName={inputLabelClassName ?? ""}
                     inputClassName={(inputClassName ?? "") + " minutesInput"}
                     reference={minutesRef}
                     {...minutesLabel}
                     type="number"
                     disabled={false}
                     autoFocus={false}
                     placeholder="0"
                     allowNegatives={false}
                     max={60}
                 />
                 {includeSeconds ? (
                     <Input
                         defaultValue={timeValues.seconds ?? 0}
                         onChange={updateTime}
                         wrapperClassName={inputWrapClassName ?? ""}
                         labelClassName={inputLabelClassName ?? ""}
                         inputClassName={(inputClassName ?? "") + " secondsInput"}
                         reference={secondsRef}
                         label="Seconds"
                         type="number"
                         disabled={false}
                         autoFocus={false}
                         placeholder="0"
                         allowNegatives={false}
                         max={60}
                     />
                 ) : ""}
             </div>
         </div>
    )
}