import React, {useEffect, useState} from 'react';

type Props = {
    label?: string,
    reference: React.Ref<HTMLInputElement>,
    type: string,
    value?: string,
    placeholder?: string,
    wrapperClassName?: string,
    inputClassName?: string,
    labelClassName?: string,
    autoFocus?: boolean,
    disabled?: boolean,
    onKeyPress?: Function | null,
    defaultValue?: string | number,
    allowNegatives?: boolean,
    max?: number | null,
    id?: string | null,
    onChange?: Function| null
}
 type ValueProps = {
    defaultValue: string | number | undefined,
    value: string | number | undefined
 }

export const Input = ({label, reference, type, placeholder, value, wrapperClassName, labelClassName, inputClassName, autoFocus = false, disabled = false, onKeyPress = null, allowNegatives = false, max = null, id = null, onChange = null, defaultValue}: Props): JSX.Element => {
    let defaultValueProps = {};
    let valueProps = {};

    const [values, setValues] = useState({
        defaultValue: defaultValue,
        value: value
    } as ValueProps);

    const [savedEvent, setSavedEvent] = useState(null as React.KeyboardEvent | null);

    useEffect(() => {
        setValues({
           defaultValue: defaultValue,
           value: value
        })

        if (savedEvent) {
            const timer = setTimeout(() => {
                if (onChange) {
                    onChange(savedEvent);
                }

                if (onKeyPress) {
                    onKeyPress(savedEvent);
                }

                setSavedEvent(null);
            }, 1000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [defaultValue, value, savedEvent]);

    if (values.defaultValue) {
        let inputValue = (values.defaultValue ?? 0) == 0 ? 0 : values.defaultValue;

       defaultValueProps = {
           defaultValue: inputValue
       }

       valueProps = {}
    } else {
       defaultValueProps = {}

       valueProps = {
           value: values.value
       }
    }

    const onKeyPressFunction = async (event: React.KeyboardEvent) => {
        let keyCode = event.key;
        let inputValue = (event.target as HTMLInputElement).value;

        if (type === 'currency' || type === 'number') {
            let regexValidation = /[0-9]|Backspace|\.|TAB|Tab|ArrowUp|ArrowDown|ArrowRight|ArrowLeft/;
            if (!regexValidation.test(keyCode) || (max && parseInt(inputValue + keyCode as string) > max)) {
                event.preventDefault();
                return false
            }

            if (keyCode === "ArrowUp") {
                let newValue = values.value !== undefined && true ? Number(inputValue ?? 0) + 1 : undefined;
                let newDefaultValue = values.defaultValue !== undefined && true ? Number(inputValue ?? 0) + 1 : undefined;

                if (newDefaultValue === undefined && newValue === undefined) {
                    newDefaultValue = Number(inputValue ?? 0) + 1;
                }

                setValues({
                    defaultValue: defaultValue,
                    value: value
                });

                (event.target as HTMLInputElement).value = newDefaultValue !== undefined && true ? String(newDefaultValue) : String(newValue);
            } else if (keyCode === "ArrowDown") {
                let newValue = values.value !== undefined && true ? Number(inputValue ?? 0) - 1 : undefined;
                let newDefaultValue = values.defaultValue !== undefined && true ? Number(inputValue ?? 0) - 1 : undefined;

                if (newDefaultValue === undefined && newValue === undefined) {
                    newDefaultValue = Number(inputValue ?? 0) - 1;
                }

                if ((max && ((newDefaultValue ?? 0) > max || (newValue ?? 0) > max))
                    || (!allowNegatives && ((newDefaultValue ?? 0) < 0 || (newValue ?? 0) < 0))) {
                    event.preventDefault();
                    return false;
                }

                setValues({
                    defaultValue: newDefaultValue,
                    value: newValue
                });

                (event.target as HTMLInputElement).value = newDefaultValue !== undefined && true ? String(newDefaultValue) : String(newValue);
            }
        }

        setSavedEvent(event);

        return true;
    }

    return (
        <div className={wrapperClassName ?? ''}>
            {label === undefined ? '' : <label className={labelClassName ?? ''}>{label}</label> }
            <input
                id={id ?? ''}
                min={allowNegatives ? "" : 0}
                max={max !== null ? max : ""}
                {...valueProps}
                {...defaultValueProps}
                onKeyDown={onKeyPressFunction}
                className={inputClassName ?? ''}
                ref={reference}
                type={type === 'currency' || type === 'number' ? 'text' : type}
                disabled={disabled}
                autoFocus={autoFocus}
                placeholder={placeholder}
            />
        </div>
    );
}