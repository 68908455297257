import React, {useRef, useState} from "react";
import {Button} from "../Button";

type Props = {
    defaultValue: number,
    onChange: Function,
    allowArrows: boolean,
    index: number,
    wrapperClassName?: string,
}

export const Counter = ({defaultValue, onChange, allowArrows, index, wrapperClassName}: Props) => {
    const counterRef = useRef<HTMLInputElement>({} as HTMLInputElement);
    const [counter, setCounter] = useState(defaultValue as number);

    const incrementCount = async (value: number | null = null, increment: boolean = true) => {
        if (value === null) {
            value = parseInt(counterRef.current.value);
        }

        return increment ? value + 1 : value === 1 ? value : value - 1;
    }

    const validate = async (event: React.KeyboardEvent) => {
        let keyCode = event.key;

        let regexValidation = /[0-9]|Backspace|ArrowUp|ArrowDown/;

        if (!regexValidation.test(keyCode)) {
            return false;
        }

        let currentValue = (event.currentTarget as HTMLInputElement).value;

        let newValue: number | null = null;

        if (keyCode === 'ArrowUp' && allowArrows) {
            newValue = await incrementCount(parseInt(currentValue));
        } else if (keyCode === 'ArrowDown' && allowArrows && (currentValue !== '1')) {
            newValue = await incrementCount(parseInt(currentValue), false);
        }

        if (newValue !== null && (newValue.toString() !== currentValue)) {
            counterRef.current.value = newValue.toString();
            onChange(newValue, index);
        }

        return true;
    }

    const valueChange = (event: React.ChangeEvent) => {
        onChange(parseInt((event.target as HTMLInputElement).value), index);
    }

    return (
       <div className={"flex items-center space-x-2 cursor-pointer " + (wrapperClassName ?? "")}>
          <Button className="h-6" action={() => {incrementCount(null, false).then(response => {
                  counterRef.current.value = response.toString();
                   onChange(response, index)
              })}}>
              <i  className="p-1 text-sm fa-solid fa-minus border rounded-full"></i>
           </Button>
           <input defaultValue={counter} ref={counterRef} onKeyDown={validate} onChange={valueChange} className="text-center font-bold p-1 w-8 rounded-sm outline-0 text-sm h-6 bg-transparent" type="text" />
           <Button className="h-6" action={() => {incrementCount().then(response => {
                  counterRef.current.value = response.toString();
                  onChange(response, index);
               })}}>
               <i  className="p-1 text-sm fa-solid fa-plus border rounded-full"></i>
           </Button>
       </div>
    )
}