import {Input} from "../../../LoaderUI";
import {EditButton} from "../../../core/Presentation/components/EditButton";
import {useEffect, useRef} from "react";

export const QuoteHeader = ({customerDetails, contactDetails, billingAddress, datePaid, dateCreated, dateSent, datePrinted}: QuoteHeaderDetails) => {
    let customerNameRef = useRef({} as HTMLInputElement);
    let customerEmailRef = useRef({} as HTMLInputElement);
    let customerNumberRef = useRef({} as HTMLInputElement);
    let customerWebsiteRef = useRef({} as HTMLInputElement);

    let contactNameRef = useRef({} as HTMLInputElement);
    let contactEmailRef = useRef({} as HTMLInputElement);
    let contactPrimaryNumberRef = useRef({} as HTMLInputElement);
    let contactSecondaryNumberRef = useRef({} as HTMLInputElement);

    let dateCreatedRef = useRef({} as HTMLInputElement);
    let dateSentRef = useRef({} as HTMLInputElement);
    let datePrintedRef = useRef({} as HTMLInputElement);
    let datePaidRef = useRef({} as HTMLInputElement);

    let billingAddressLineOneRef = useRef({} as HTMLInputElement);
    let billingAddressLineTwoRef = useRef({} as HTMLInputElement);
    let billingAddressLineThreeRef = useRef({} as HTMLInputElement);
    let billingAddressCityRef = useRef({} as HTMLInputElement);
    let billingAddressPostCodeRef = useRef({} as HTMLInputElement);

    const editQuoteHeader = () => {
        console.log("Edit Quote");
    }

    useEffect(() => {
        customerNameRef.current.value = customerDetails.name as string;
        customerEmailRef.current.value = customerDetails.email as string;
        customerNumberRef.current.value = customerDetails.telephoneNumber as string;
        customerWebsiteRef.current.value = customerDetails.website as string;

        contactNameRef.current.value = (contactDetails.firstName as string) + " " + (contactDetails.lastName as string);
        contactEmailRef.current.value = contactDetails.email as string;
        contactPrimaryNumberRef.current.value = contactDetails.primaryTelephoneNumber as string;
        contactSecondaryNumberRef.current.value = contactDetails.secondaryTelephoneNumber as string;

        billingAddressLineOneRef.current.value = billingAddress.lineOne as string;
        billingAddressLineTwoRef.current.value = billingAddress.lineTwo as string;
        billingAddressLineThreeRef.current.value = billingAddress.lineThree as string;
        billingAddressCityRef.current.value = billingAddress.city as string;
        billingAddressPostCodeRef.current.value = billingAddress.postcode as string;

        dateCreatedRef.current.value = dateCreated as string;
        datePaidRef.current.value = datePaid as string;
        dateSentRef.current.value = dateSent as string;
        datePrintedRef.current.value = datePrinted as string;
    });

    return (
        <div id="quote-header" className="text-2xl grid">
            <div id="quote-information"
                 className="grid grid-cols-10 bg-gray-300 rounded-md border border-gray-300 w-full relative">
                <div id="customer-information" className="col-span-3">
                    <Input
                        wrapperClassName="grid text-sm p-4 w-auto"
                        labelClassName="pl-1 text-xs text-gray-600 font-semibold"
                        inputClassName="pl-1 text-sm text-gray-900 bg-gray-300 disabled"
                        reference={customerNameRef}
                        label="Customer Name"
                        type="text"
                        disabled={true}
                        autoFocus={false}
                        placeholder=""
                    />
                    <div id="customer-contact-details" className="flex w-full">
                        <Input
                            value="01773764896"
                            wrapperClassName="grid text-sm py-4 pr-2 pl-4 w-2/6"
                            labelClassName="pl-1 pr-0 text-xs text-gray-600 font-semibold"
                            inputClassName="pl-1 pr-0 text-sm text-gray-900 bg-gray-300  disabled"
                            reference={customerNumberRef}
                            label="Customer No"
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />
                        <Input
                            wrapperClassName="grid text-sm py-4 px-4 w-4/6"
                            labelClassName="text-xs text-gray-600 font-semibold"
                            inputClassName="text-sm text-gray-900 bg-gray-300  disabled"
                            reference={customerEmailRef}
                            label="Customer Email"
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />
                    </div>
                    <Input
                        value="declanbrown.co.uk"
                        wrapperClassName="grid text-sm p-4 w-auto"
                        labelClassName="pl-1 text-xs text-gray-600 font-semibold"
                        inputClassName="pl-1 text-sm text-gray-900 bg-gray-300 disabled"
                        reference={customerWebsiteRef}
                        label="Customer Website"
                        type="text"
                        disabled={true}
                        autoFocus={false}
                        placeholder=""
                    />
                </div>
                <div id="contact-information" className="col-span-3">
                    <Input
                        wrapperClassName="grid text-sm p-4 w-auto"
                        labelClassName="pl-1 text-xs text-gray-600 font-semibold"
                        inputClassName="pl-1 text-sm text-gray-900 bg-gray-300 disabled"
                        reference={contactNameRef}
                        label="Contact Name"
                        type="text"
                        disabled={true}
                        autoFocus={false}
                        placeholder=""
                    />
                    <div id="contact-details" className="flex w-full">
                        <Input
                            wrapperClassName="grid text-sm py-4 pr-2 pl-4 w-2/6"
                            labelClassName="pl-1 pr-0 text-xs text-gray-600 font-semibold"
                            inputClassName="pl-1 pr-0 text-sm text-gray-900 bg-gray-300  disabled"
                            reference={contactPrimaryNumberRef}
                            label="Primary No"
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />
                        <Input
                            wrapperClassName="grid text-sm py-4 px-4 w-4/6"
                            labelClassName="text-xs text-gray-600 font-semibold"
                            inputClassName="text-sm text-gray-900 bg-gray-300  disabled"
                            reference={contactSecondaryNumberRef}
                            label="Secondary No"
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />
                    </div>
                    <Input
                        wrapperClassName="grid text-sm py-4 px-4 w-3/4"
                        labelClassName="text-xs text-gray-600 font-semibold"
                        inputClassName="text-sm text-gray-900 bg-gray-300  disabled"
                        reference={contactEmailRef}
                        label="Contact Email"
                        type="text"
                        disabled={true}
                        autoFocus={false}
                        placeholder=""
                    />
                </div>
                <div id="document-information" className="grid grid-cols-2 col-span-4">
                    <div id="document-statuses">
                        <Input
                            wrapperClassName="grid text-sm p-4"
                            labelClassName="text-xs text-gray-600 font-semibold"
                            inputClassName="text-sm text-gray-900 bg-gray-300  disabled"
                            reference={dateCreatedRef}
                            label="Date Created"
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />
                        <Input
                            value="Not Sent"
                            wrapperClassName="grid text-sm p-4"
                            labelClassName="text-xs text-gray-600 font-semibold"
                            inputClassName="text-sm text-gray-900 bg-gray-300  disabled"
                            reference={dateSentRef}
                            label="Sent Status"
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />
                        <Input
                            value="Printed"
                            wrapperClassName="grid text-sm p-4"
                            labelClassName="text-xs text-gray-600 font-semibold"
                            inputClassName="text-sm text-gray-900 bg-gray-300  disabled"
                            reference={datePrintedRef}
                            label="Print Status"
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />
                    </div>
                    <div id="billing-details">
                        <Input
                            value="N/A"
                            wrapperClassName="grid text-sm p-4"
                            labelClassName="text-xs text-gray-600 font-semibold"
                            inputClassName="text-sm text-gray-900 bg-gray-300  disabled"
                            reference={datePaidRef}
                            label="Date Paid"
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />
                        <Input
                            wrapperClassName="grid text-sm pb-0 pt-4 px-4"
                            labelClassName="text-xs text-gray-600 font-semibold"
                            inputClassName="text-sm text-gray-900 bg-gray-300  disabled"
                            reference={billingAddressLineOneRef}
                            label="Billing Address"
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />
                        <Input
                            wrapperClassName="grid text-sm py-0 px-4"
                            inputClassName="text-sm text-gray-900 bg-gray-300  disabled"
                            reference={billingAddressLineTwoRef}
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />
                        <Input
                            wrapperClassName="grid text-sm py-0 px-4"
                            inputClassName="text-sm text-gray-900 bg-gray-300  disabled"
                            reference={billingAddressLineThreeRef}
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />
                        <Input
                            wrapperClassName="grid text-sm py-0 px-4"
                            inputClassName="text-sm text-gray-900 bg-gray-300  disabled"
                            reference={billingAddressCityRef}
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />
                        <Input
                            wrapperClassName="grid text-sm py-0 pb-4 px-4 w-3/4"
                            inputClassName="text-sm text-gray-900 bg-gray-300  disabled"
                            reference={billingAddressPostCodeRef}
                            type="text"
                            disabled={true}
                            autoFocus={false}
                            placeholder=""
                        />
                    </div>
                </div>
                <EditButton action={editQuoteHeader}/>
            </div>
        </div>
    )
}